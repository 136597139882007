import React, { useState } from 'react';
import { useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const Types = {
  STATEMENT: 'statement',
};

const Statement = ({ statement,from ,source}) => {
  const [, ref] = useDrag({
    type: Types.STATEMENT,
    item: { id: statement?.id ,from:from,source:source},
  });

  return (
    <div
      ref={ref}
      style={from!='inside'? {
        margin: '5px',
        padding: '10px',
        background: 'white',
        borderRadius: '10px',
        boxShadow: '4px 4px 16px white',
        border: '1px solid black',
        maxWidth: '300px',
        wordWrap: 'break-word',
        cursor: 'grab',
      } :{cursor:'grab'}}
    >
      {statement?.Statement}
    </div>
  );
};

const StatementsList = ({ statements, onDrop, currentStatementIndex }) => {
  return (
    <div>
      <h1>Statements</h1>
      {statements[currentStatementIndex] ? (
        <Statement statement={statements[currentStatementIndex]} from={'statements'} />
      ) : (
        'No statement available!'
      )}
    </div>
  );
};

const DropZone = ({ accept, onDrop, droppedStatements = [], setstatements,source }) => {
  const [{ isOver }, drop] = useDrop({
    accept,
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });


  return (
    <div
      ref={(node) => {
        drop(node);
      }}
      style={{
        width: '300px',
        height: '300px',
        overflow: 'auto',
        border: '1px dashed black',
        backgroundColor: isOver ? '#e6ffe6' : 'white',
        cursor: 'move',
      }}
    >
      {droppedStatements.length === 0 && 'Drop here'}
      {droppedStatements?.map((statement) => (
        <div
          key={statement?.id}
          style={{
            margin: '5px',
            padding: '10px',
            background: 'white',
            borderRadius: '10px',
            boxShadow: '4px 4px 16px white',
            border: '1px solid black',
            maxWidth: '300px',
            wordWrap: 'break-word',
          }}
        >
          <Statement statement={statement} from={"inside"} source={source}/>
        </div>
      ))}
    </div>
  );
};
export const removeId = (statement) => {
  const { id, ...rest } = statement;
  return rest;
};
const ShuttleControl = ({statements,setUpdatedListData,key, oldValues}) => {
  const [trueStatements, setTrueStatements] = useState(oldValues?.trueStatments || []);
  const [falseStatements, setFalseStatements] = useState(oldValues?.falseStatments || []);
  console.log("true statemetn",trueStatements,"false statement",falseStatements)
  const [currentStatementIndex, setCurrentStatementIndex] = useState(0);
   useEffect(()=>{
   

    const updatedTrueStatements = trueStatements;
    const updatedFalseStatements = falseStatements;

    setUpdatedListData([...updatedTrueStatements, ...updatedFalseStatements]);
    // setUpdatedListData([...trueStatements,...falseStatements])
   },[trueStatements,falseStatements])


  //  useEffect(()=>{
  //   getData();
  //  },[])

  // const statements = [
  //   { id:1,option: 'TRUE', text: 'Data can be collected by survey.' },
  //   { id:2,option: 'FALSE', text: 'Rows represent observations and columns represent records.' },
  //   { id:3,option: 'FALSE', text: 'If numbers are used to differentiate categories, we can take sum to know total categories.' },
  //   {id:4 ,option: 'FALSE', text: 'Variables are observations' },
  //   { id:5,option: 'TRUE', text: 'Mainly, there are two types of data: categorical and numerical' },
  //   {id:6 ,option: 'TRUE', text: 'Quantitative data type is also known as numerical data type.' },
  //   {id:7 ,option: 'TRUE', text: 'Sum, averages are performed on only numeric data' },
  //   { id:8,option: 'TRUE', text: 'We take count of categories and not sum of categories.' },
  // ]

  const handleDrop = (itemId, item, isTrue) => {
  //   if(item.from !==isTrue){
    if (item.from === 'statements') {
     return handleDropForStatements(itemId, isTrue);
    } 
    else{
      if(item.source!=isTrue){
      handleDropForDropZone(itemId, isTrue);
      }
}
    // else {
  //   }
  // }
  };
  
  const handleDropForStatements = (itemId, isTrue) => {
    const statement = statements.find((s) => s.id === itemId);
    const updatedStatements = statements.filter((s) => s.id !== itemId);
  
    if (isTrue) {
      setTrueStatements((prev) => [...prev, {...statement,option:'TRUE'}]);
    } else {
      setFalseStatements((prev) => [...prev,{...statement,option:'FALSE'}]);
    }
  
    setCurrentStatementIndex((prev) => prev + 1);
  };
  
  const handleDropForDropZone = (itemId, isTrue) => {
    if (isTrue) {
      const statement = falseStatements.find((s) => s.id === itemId);
      const updatedStatements = falseStatements.filter((s) => s.id !== itemId);
  
      setTrueStatements((prev) => [...prev, {...statement,option:'TRUE'}]);
      setFalseStatements(updatedStatements);
    } else {
      const statement = trueStatements.find((s) => s.id === itemId);
      const updatedStatements = trueStatements.filter((s) => s.id !== itemId);
  
      setFalseStatements((prev) => [...prev, {...statement,option:'FALSE'}]);
      setTrueStatements(updatedStatements);
    }
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <div>
          <StatementsList
            statements={statements}
            currentStatementIndex={currentStatementIndex}
          />
        </div>
        <div style={{ display: 'flex', gap: '0.5rem', flexDirection: 'row' }}>
          <div>
            <h2>True</h2>
            <DropZone
              accept={Types.STATEMENT}
              onDrop={(item) => handleDrop(item.id, item,true)}
              droppedStatements={trueStatements}
              setstatements={setTrueStatements}
              source={true}
            />
          </div>
          <div>
            <h2>False</h2>
            <DropZone
              accept={Types.STATEMENT}
              onDrop={(item) => handleDrop(item.id, item,false)}
              droppedStatements={falseStatements}
              setstatements={setFalseStatements}
              source={false}

            />
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default ShuttleControl;
















// import React, { useState } from 'react';
// // import libraries
// import { MdDelete } from "react-icons/md";
// import { Tooltip } from 'react-tooltip';
// // import styles
// import './shuttle.scss'; // Import your CSS file

// const initialItems = [
//   { option: 'TRUE', statement: 'Data can be collected by survey.' },
//   { option: 'FALSE', statement: 'Rows represent observations and columns represent records.' },
//   { option: 'FALSE', statement: 'If numbers are used to differentiate categories, we can take sum to know total categories.' },
//   { option: 'FALSE', statement: 'Variables are observations' },
//   { option: 'TRUE', statement: 'Mainly, there are two types of data: categorical and numerical' },
//   { option: 'TRUE', statement: 'Quantitative data type is also known as numerical data type.' },
//   { option: 'TRUE', statement: 'Sum, averages are performed on only numeric data' },
//   { option: 'TRUE', statement: 'We take count of categories and not sum of categories.' },
// ]
// const ShuttleControl = () => {
//   const [selectedTrueItems, setSelectedTrueItems] = useState([]);
//   const [selectedFalseItems, setSelectedFalseItems] = useState([]);
//   const [unselectedItems, setUnselectedItems] = useState(initialItems);
//   const [checkedItems, setCheckedItems] = useState(Array(initialItems.length).fill(false));

//   const toggleSelect = (index) => {
//     const newCheckedItems = [...checkedItems];
//     newCheckedItems[index] = !newCheckedItems[index];
//     setCheckedItems(newCheckedItems);
//   };

//   const moveSelectedStatement = (option) => {
//     if (checkedItems.some(item => item)) {
//       const newUnselectedItems = unselectedItems.filter((_, index) => !checkedItems[index]);
//       const selectedItems = unselectedItems.filter((_, index) => checkedItems[index]);

//       if (option === 'TRUE') {
//         setSelectedTrueItems([...selectedTrueItems, ...selectedItems]);
//       } else {
//         setSelectedFalseItems([...selectedFalseItems, ...selectedItems]);
//       }

//       const updatedUnselectedItems = unselectedItems.filter(item => !selectedItems.includes(item));
//       setUnselectedItems(updatedUnselectedItems);
//       setCheckedItems(Array(updatedUnselectedItems.length).fill(false));
//     }
//   };

//   const toggleDeselect = (index, option) => {
//     if (option === 'TRUE') {
//       setSelectedTrueItems(selectedTrueItems.filter((_, i) => i !== index));
//     } else {
//       setSelectedFalseItems(selectedFalseItems.filter((_, i) => i !== index));
//     }

//     const removedItem = option === 'TRUE' ? selectedTrueItems[index] : selectedFalseItems[index];
//     setUnselectedItems([...unselectedItems, removedItem]);
//   };

//   const removeAllSelected = () => {
//     setUnselectedItems(initialItems);
//     setSelectedTrueItems([]);
//     setSelectedFalseItems([]);
//     setCheckedItems(Array(initialItems.length).fill(false));
//   };

//   return (
//     <div className="shuttle-control">
//       <Tooltip
//         style={{ zIndex: 99 }}
//         anchorSelect="#delete"
//         content="Delete"
//         place="top"
//       />
//       <div className="list-container">
//         <h2>Statements</h2>
//         <section className='shuttle-list'>
//           <ul>
//             {unselectedItems.map((item, index) => (
//               <li key={index}>
//                 <div className='shuttle-list-input'>
//                   <input type="checkbox" checked={checkedItems[index]} onClick={() => toggleSelect(index)} />
//                 </div>
//                 <p>
//                   {item.statement}
//                 </p>
//               </li>
//             ))}
//           </ul>
//         </section>
//       </div>
//       <div className="button-container">
//         <button onClick={() => moveSelectedStatement('TRUE')}>True</button>
//         <button onClick={removeAllSelected}>Remove All</button>
//         <button onClick={() => moveSelectedStatement('FALSE')}>False</button>
//       </div>
//       <section className='all-selected-items'>
//         <div className="selected-items">
//           <h2>True</h2>
//           <ul>
//             {selectedTrueItems.map((item, index) => (
//               <li key={index}>
//                 {/* <div className='all-selected-items-input'>
//                   <input type="checkbox" />
//                 </div> */}
//                 <p>
//                   {item.statement}
//                 </p>
//                 <div className='all-selected-items-btn'>
//                   <button id='delete' onClick={() => toggleDeselect(index, 'TRUE')}>
//                     <MdDelete size={18} />
//                   </button>
//                 </div>
//               </li>
//             ))}
//           </ul>
//         </div>
//         <div className="selected-items">
//           <h2>False</h2>
//           <ul>
//             {selectedFalseItems.map((item, index) => (
//               <li key={index}>
//                 {/* <div className='all-selected-items-input'>
//                   <input type="checkbox" />
//                 </div> */}
//                 <p>
//                   {item.statement}
//                 </p>
//                 <div className='all-selected-items-btn'>
//                   <button id='delete' onClick={() => toggleDeselect(index, 'FALSE')}>
//                     <MdDelete size={18} />
//                   </button>
//                 </div>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </section>

//     </div>
//   );
// };

// export default ShuttleControl;
