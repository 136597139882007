import './Viewuserdetails.scss';
import React, { useEffect, useState } from 'react';
import { handleFileUpload } from '../../Schedule/scheduleutils';
import { Button, DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material';
import ApiConfig from '../../../ApiConfig';
import axios from 'axios'
import xlfile from './Sample_userids.xlsx'
import { FaDownload } from "react-icons/fa6";

import { toast, ToastContainer } from 'react-toastify';
export default function UpdateUserDetails({ userDetails, setshowDialogupdate, showDialogupdate }) {
    const [FileTypeError, setFileTypeError] = useState(null);
    const [data, setData] = useState([]);
    const [filterdarray, setFilterdarray] = useState([]);
    const [filename, setFilename] = useState('File name');
    let Api = new ApiConfig()
    useEffect(() => {
        const userIds = new Set(userDetails.map(obj => obj.userid));
        const filteredArray = data.filter(obj => userIds.has(obj.userid));
        setFilterdarray(filteredArray);
        console.log("filterara", filterdarray)
    }, [data]);

    async function handleUpdateApi() {

        let response = await axios.post(Api.addApiKey(`${Api.BaseURI}/updateAssessmentDetails`), { Userid: filterdarray })
        console.log("response handleUpdateApi", response.data)
        setshowDialogupdate(false)
        return toast.success(`${response.data.success} user's details updated out of ${response.data.total}`)


    }

    return (
        <>

            <div>
                <ToastContainer position="top-center"
                    autoClose={2000} />

            </div>
            <Dialog open={showDialogupdate}>
                <DialogTitle>
                    Update User Details
                </DialogTitle>
                <DialogContent>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <section className='schedule_selectuserids'>
                            <section className="schedule_selectuserids_input_samplefile">
                                <section className="schedule_selectuserids_input">
                                    <label htmlFor="selectuserids_li" className="selectuserids_label">
                                        Select CSV or Excel File
                                    </label>
                                    <div className="selectuserids_input_filename">
                                        <input
                                            id="selectuserids_li"
                                            type="file"
                                            accept=".csv, .xlsx, .xls"
                                            onChange={(e) =>
                                                handleFileUpload(e.target.files[0], setFilename, setFileTypeError, setData)
                                            }
                                            title={`${filename}`}
                                        />
                                        <div className="selectuserids_filename">{filename}</div>
                                    </div>
                                </section>
                                {FileTypeError && (
                                    <div className="error-message">
                                        {FileTypeError}
                                    </div>
                                )}
                            </section>
                        </section>
                        <span style={{display:'flex',marginLeft:'auto'}}>
                            <a
                                href={xlfile}
                                download="Sample_userids"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button
                                    className="link-button small selectuserids_samplefile">
                                        
                                    Download sample file &nbsp;&nbsp;
                                    <FaDownload size={15}  />
                                </button>
                            </a>
                        </span>
                    </div>



                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUpdateApi()}>
                        Update & Save
                    </Button>
                    <Button onClick={() => setshowDialogupdate(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
}
