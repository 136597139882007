export function Item({ id, description, isDragging }) {
  const style = {
    padding: "10px 15px",
    border: "1px solid #dcdcdc",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    color: "#333333",
    fontSize: "14px",
    fontWeight: 500,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    cursor: isDragging ? "grabbing" : "grab",
  };
  return (
    <div style={style}>
      <strong>{id}</strong>: {description}
    </div>
  );
}
