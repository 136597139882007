import { arrayMove } from "@dnd-kit/sortable";
import { findContainer } from "./findContainer";

export function handleDragEnd(event, tasks, setTasks, setActiveId) {
  const { active, over } = event;
  const activeId = active?.id;
  const overId = over?.id;

  if (!overId || activeId === overId) {
    setActiveId(null);
    return;
  }

  const activeContainer = findContainer(activeId, tasks);
  const overContainer = findContainer(overId, tasks);

  if (!activeContainer || !overContainer) {
    setActiveId(null);
    return;
  }

  if (activeContainer === overContainer) {
    const containerItems = tasks[activeContainer];
    const activeIndex = containerItems.findIndex(
      (item) => item.id === activeId
    );
    const overIndex = containerItems.findIndex((item) => item.id === overId);

    if (activeIndex !== overIndex) {
      setTasks((prev) => ({
        ...prev,
        [activeContainer]: arrayMove(containerItems, activeIndex, overIndex),
      }));
    }
  }

  setActiveId(null);
}
// Compare this snippet from src/widgets/Shuffle/Shuffle3.jsx:
