import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import React, { useEffect, useState } from "react";
import { Container } from "./Container";
import { Overlay } from "./Overlay";
import "./shuffle.scss";
import { handleDragEnd } from "./utils/handleDragEnd";
import { handleDragOver } from "./utils/handleDragOver";
import { handleDragStart } from "./utils/handleDragStart";
import { handleGetIds } from "./utils/handleGetIds";

export default function Shuffle3({
  columnNames,
  INITIAL_TASKS,
  setShuffleValues,
}) {
  const [tasks, setTasks] = useState(INITIAL_TASKS);
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  console.log("tasks", tasks);

  useEffect(() => {
    const taskIds = handleGetIds(columnNames, tasks);
    console.log("taskIds", taskIds);
    setShuffleValues(taskIds);
  }, [tasks]);

  return (
    <div className="shuffle-column-wrapper">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={(e) => handleDragStart(e, setActiveId)}
        onDragOver={(e) => handleDragOver(e, setTasks, tasks)}
        onDragEnd={(e) => handleDragEnd(e, tasks, setTasks, setActiveId)}
      >
        {columnNames.map((name, index) => (
          <Container
            key={`column${index + 1}`}
            id={`column${index + 1}`}
            tasks={tasks[`column${index + 1}`]}
          />
        ))}
        <Overlay
          activeId={activeId}
          tasks={tasks}
        />
      </DndContext>
    </div>
  );
}
