import { equals } from "./comparisons";

export const arrayUtils = {
  mapItems: mapItems,
  findIndexBy: findIndexBy,
  filterItems: filterItems,
  generateNumbers: generateNumbers,
  generateRandomNumbers: generateRandomNumbers,
};

function filterItems(
  array,
  value,
  transform = (item, value) => equals(item, value)
) {
  return array.filter((item) => transform(item, value));
}

function findIndexBy(
  array,
  value,
  transform = (item, value) => equals(item, value)
) {
  return array.findIndex((item) => transform(item, value));
}

function mapItems(array, transform = (n) => n) {
  return array.map(transform);
}

function generateNumbers(length = 10, transform = (n) => n) {
  return Array.from({ length: length }, (_, index) => transform(index + 1));
}

function generateRandomNumbers(
  count = 10,
  min = 0,
  max = 100,
  transform = (n) => n
) {
  return Array.from({ length: count }, () =>
    transform(Math.floor(Math.random() * (max - min + 1)) + min)
  );
}
