import React, { useState } from "react";
import "./Evalform.scss";
import RenderMatchPairs from "widgets/MatchPairs/RenderMatchPairs";
// import { RenderShuffle } from "widgets/Shuffle/renderShuffle";

// import ApiConfig from "../../ApiConfig";
import ShuttleControl from "widgets/Shuttle/Type1/Shuttle";
import SqlDrag from "widgets/SqlDragItems/Sqldrag";
import { getValueFromSession } from "../../UtilityFunctions/getSetValueSession";
import { RenderShuffle } from "widgets/Shuffle/renderShuffle";
export default class DynamicForm extends React.Component {
  state = {};
  constructor(props) {
    super(props);

    // console.log("props constructor", props);
    // this.state={
    //   evaluationResul:[],
  }

  static getDerivedStateFromProps(Props, State) {
    // console.log("gds:p:s", Props, State);
    return null;
  }

  clearTimer(timeOutid) {
    while (timeOutid--) {
      clearTimeout(timeOutid);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.props.onSubmit) this.props.onSubmit(this.state);
  };

  toCheckAnswers = (fromPrac) => {
    if (this.props.toCheckAnswers)
      this.props.toCheckAnswers(this.state, fromPrac);
  };

  toEvaluate = (finished) => {
    if (this.props.toEvaluate) this.props.toEvaluate(this.state, finished);
  };


  tochangevalues = (key, value) => {
    // const { tochangevalues } = useToEvaluate();
    // tochangevalues(key,value);
    if (this.props.tochangevalues) this.props.tochangevalues(key, value);
  };

  callbackfunc = () => {
    this.toEvaluate()
  }

  onChange = (e, key, type = "single") => {
    // console.log(`${e.target.value} type ${type}`);
    // if(props)
    // this.tochangevalues(key, e.target.value);
    if (type === "single") {
      // console.log(key, e.target.value);
      if (e.target.value === "") {
        console.log("No value", key)
        this.props.setKey(key)
        this.props.setValue(e.target.value)
        this.tochangevalues(key, e.target.value);
      }
      this.setState(
        {
          [key]: e.target.value,
        },
        () => { }
      );

      this.props.statenp(
        {
          ...this.props.npvalue,
          [key]: e.target.value,
        }
      )
      // console.log("key", key)
      // console.log("e.target.value", e.target.value)
    } else {
      // Array of values (e.g. checkbox): TODO: Optimization needed.
      let found = this.state[key]
        ? this.state[key].find((d) => d === e.target.value)
        : false;

      if (found) {
        let data = this.state[key].filter((d) => {
          return d !== found;
        });
        this.setState({
          [key]: data,
        });
        this.props.statenp({
          ...this.props.npvalue,
          [key]: data,
        })
      } else {
        console.warn("found", key, this.state[key]);
        // this.setState({
        //   [key]: [e.target.value, ...this.state[key]]
        // });
        let others = this.state[key] ? [...this.state[key]] : [];
        this.setState({
          [key]: [e.target.value, ...others],
        });
        this.props.statenp({
          ...this.props.npvalue,
          [key]: [e.target.value, ...others],
        })
      }
    }
  };

  renderTable = (options, value, key1) => {
    console.log("optipns", options);
    console.log("key", key1);
    console.log("data", this.state);
    let extractedData = this.props?.answers[value - 1]?.answer;
    let columnNames = extractedData
      .filter((item) => item.column !== undefined)
      .map((item) => item);
    console.log("column names", columnNames);
    let tablerows =
      extractedData.find((item) => item.tablerows !== undefined)?.tablerows ||
      [];
    const deleteLastRow = (e) => {
      e.preventDefault();
      if (tablerows.length > 0) {
        const updatedTablerows = tablerows.slice(0, -1);
        const updatedExtractedData = extractedData.map((item) => {
          if (item.tablerows !== undefined) {
            return { ...item, tablerows: updatedTablerows };
          }
          return item;
        });

        this.setState((prevState) => ({
          tabledata: prevState.tabledata.map((item, i) => {
            if (i === value) {
              return {
                ...item,
                answer: updatedExtractedData,
              };
            }
            return item;
          }),
        }));
      }
    };
    const addRow = (e, index) => {
      e.preventDefault();
      const newRow = {};
      columnNames.forEach((columnName) => {
        newRow[columnName.column] = "";
      });

      const updatedTablerows = [...tablerows, newRow];

      this.setState((prevState) => ({
        ...prevState,
        [key1]: updatedTablerows,
      }));
      const updatedExtractedData = extractedData.map((item) => {
        if (item.tablerows !== undefined) {
          return { ...item, tablerows: updatedTablerows };
        }
        return item;
      });

      this.setState((prevState) => ({
        tabledata: prevState.tabledata.map((item, i) => {
          if (i === value) {
            return { ...item, answer: updatedExtractedData };
          }
          return item;
        }),
      }));
    };
    const handleInputChange = (e, rowIndex, columnName) => {
      let value1 = e.target.value;
      console.log("value", key1);

      const updatedTablerows = tablerows.map((row, index) => {
        if (index === rowIndex) {
          return { ...row, [columnName]: value1 };
        }
        return row;
      });

      this.setState((prevState) => ({
        ...prevState,
        [key1]: updatedTablerows,
      }));
      console.log("tafddfdf", updatedTablerows);

      const updatedExtractedData = extractedData.map((item) => {
        if (item.tablerows !== undefined) {
          return { ...item, tablerows: updatedTablerows };
        }
        return item;
      });
      console.log("updaed", updatedExtractedData);

      this.setState((prevState) => ({
        tabledata: prevState.tabledata.map((item, i) => {
          if (i == value) {
            return {
              ...item,
              answer: updatedExtractedData,
            };
          }
          return item;
        }),
      }));
    };
    return (
      <table>
        <thead>
          <tr>
            {options?.options?.map((option) => (
              <th key={option.key}>{option.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tablerows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columnNames.map((columnName, columnIndex) => (
                <td key={columnIndex}>
                  {!columnName.hasOwnProperty("Values") &&
                  !Array.isArray(columnName.Values) ? (
                    <input
                      type="text"
                      className="input"
                      value={row[columnName.column]}
                      onChange={(e) =>
                        handleInputChange(e, rowIndex, columnName.column)
                      }
                    />
                  ) : (
                    <select
                      value={row[columnName.column]}
                      onChange={(e) =>
                        handleInputChange(e, rowIndex, columnName.column)
                      }
                      className="input"
                    >
                      <option>Select...</option>
                      {columnName.Values.map((option, index) => (
                        <option
                          key={index}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={columnNames.length}>
              <button
                className="button primary"
                onClick={(e) => addRow(e)}
              >
                Add row
              </button>{" "}
              <button
                className="button secondary"
                onClick={(e) => deleteLastRow(e)}
              >
                Delete row
              </button>{" "}
              {/* Pass index to addRow */}
            </td>
          </tr>
        </tfoot>
      </table>
    );
  };

  setUpdatedListData = (newRows, key) => {
    console.log("new", newRows);
    // console.log("new Rows",this.state)
    this.setState((prevState) => ({
      ...prevState,
      [key]: newRows,
    }));
  };

  setRenderSqlArray = (newArray, key) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: newArray,
    }));
  };

  renderMultiList = (options, value, key1) => {
    console.log("keu", key1);
    console.log("value", value);
    let extractedData = this.props?.answers[value -1]?.answer;
    // console.log('multilist', extractedData )
    let statements =[]
    let statementsWithId = extractedData.map((statement, index) =>{
      statements.push(statement.Statement)
    return  {
      id: statement.id ?? index + 1,
      ...statement,
  }
});
    function getPrevValues(columns){
      // console.log('multilist',columns, )
      let results = getValueFromSession('results') || []
      if(typeof results === 'string')
        results = JSON.parse(results)
      // console.log('multilist results', results)
      // let statementsSet = Set()
      for (let i = 0; i < results.length; i++) {
        const element = results[i];
        // console.log('multilist element',element?.questionjson.filter(item=> item.key === key1))
        let value = element?.questionjson.filter(item=> item.key === key1)
        // console.log('multilist value[0]',value[0]?.value,value)
        // columns.
        if(value[0]?.value !== undefined && value[0]?.value?.length > 0){
          let trueStatments =[]
          let falseStatments =[]
           for (let i = 0; i < value[0]?.value.length; i++) {
            const element = value[0]?.value[i];
           element?.option ==='TRUE'? trueStatments.push(element):falseStatments.push(element)
           statementsWithId = statementsWithId.filter(item=> {
            console.log('multilist id check',!item.id== element.id,item.id, element.id)
            return !(item.id==element.id)
          })
          
           }
          
           return {trueStatments,falseStatments}
        }
      }
      return {trueStatments:[],falseStatments:[]}

    }
    console.log('multilist statements id',statementsWithId)
    let oldValues = getPrevValues()
    console.log('multilist  oldValues',oldValues,statementsWithId)
    return (
      <ShuttleControl
        oldValues = {oldValues}
        statements={statementsWithId}
        setUpdatedListData={(newdata) => this.setUpdatedListData(newdata, key1)}
      />
    );
  };

  renderRearrange = (options, value, key1, type) => {
    function getPrevValues(columns){
      let results = getValueFromSession('results') || []
      console.log('sql drag',results)
      if(results === undefined || results.length<=0){
        return [columns]
      }
      results = JSON.parse(results)
      
      for (let i = 0; i < results.length; i++) {
        const element = results[i];
        // console.log('sql drag',element?.questionjson.filter(item=> item.key === key1),element?.questionjson, key1)
        let value = element?.questionjson.filter(item=> item.key === key1)
        console.log('sql drag',value[0]?.value)
        let col2=value[0]?.value?.map((item,i)=>{
          console.log('sql drag',columns,item)
          if(item == columns.Statement[item -1]?.key){
            let col3 =columns.Statement[item -1]
            return col3
        }
      })||[]
        console.log('sql drag', col2)
      if(value[0]?.value?.length>0){
        return [{Statement:col2, correct:columns.correct}]

        
      }
      }
      return [columns]
    
    }
    let oldValues = getPrevValues(this.props.answers[value-1].answer[0])
    console.log('sql drag', oldValues,this.props.answers[value-1].answer[0])
    return (
      <SqlDrag
        data={oldValues || JSON.parse(this.props.answers[value-1].answer)}
        setRenderSqlArray={(datarray) => this.setRenderSqlArray(datarray, key1)}
      />
    );
  };

  setMatchPairsValues = (key, value) => {
    console.log('match  key value',key, value)
    this.props.statenp({
      ...this.props.npvalue,
      [key]: value,
    });
    this.setState(
      {
        [key]: value,
      },
      () => {}
    );
  };

  handleRenderMatchPairs = (options, value, key1) => {
    console.log("shuffle", key1);
    console.log("shuffle", value);
    console.log("shuffle", options);
    console.log("shuffle", this.props.answers);
    function getPrevValues(columns){
      console.log('match',columns, )
      let results = getValueFromSession('results') || []
      if(typeof results === 'string')
        results = JSON.parse(results)
      console.log('match', results)
      for (let i = 0; i < results.length; i++) {
        const element = results[i];
        console.log('match',element?.questionjson.filter(item=> item.key === key1))
        let value = element?.questionjson.filter(item=> item.key === key1)
        console.log('match',value[0]?.value,value)
        if(value[0]?.value !== undefined && value[0]?.value.length>0){
          return value[0]?.value 
          
        }
        // columns.
        
      }
      return []

    }
   let userAns = getPrevValues(this.props.answers[value-1].answer)
  //  console.log('match',oldValues, value)
    return (
      <RenderMatchPairs
      userAns ={userAns}
        columns={ this.props.answers[0].answer}
        setMatchPairsValues={(shuffleValue) => {
          console.log('match',shuffleValue)
          this.setMatchPairsValues(key1, shuffleValue);
        }}
      />
    );
  };

  setShuffleValues = (key, value) => {
    console.log("shuffle", key, value);
    this.setState(
      {
        [key]: value,
      },
      () => {}
    );

    this.props.statenp({
      ...this.props.npvalue,
      [key]: value,
    });
  };

  handleRenderShuffle = (options, value, key1, type) => {
    console.log("shuffle", key1);
    console.log("shuffle", value);
    console.log("shuffle", options);
    console.log("shuffle", this.props.answers);
    function getPrevValues(columns) {
      console.log("shuffle", key1, value, columns);
      let results = getValueFromSession("results") || [];
      console.log('results',typeof results)
      if(typeof results === 'string'){

        results = JSON.parse(results);
      }
      console.log("shuffle");
      for (let i = 0; i < results.length; i++) {
        const element = results[i];
        console.log('shuffle',element?.questionjson.filter(item=> item.key === key1))
        let value = element?.questionjson.filter(item=> item.key === key1)
        console.log('shuffle',value[0]?.value,value[0])
        // if(value[0].key === key1 &&value[0]?.value === undefined){
        //   return {column1:columns,column2:[]}

          
        // }
        let InCol2 =value[0]?.value?.map(item=> item.id)
        let col2=value[0]?.value?.map((item,i)=>{
          console.log('shffule',columns[item-1],item,item === columns[item -1]?.id)
          if(item === columns[item -1]?.id){
            return columns[item -1]
          }
        } )||[]
        
        let columns1 = type ==='Reorder'?col2:columns.filter(item=> !value[0]?.value?.includes(item.id))
       
        console.log(col2,columns,columns1,value[0]?.value)
       
        if(col2?.length > 0){
          return {column1:columns1,column2:col2||[]}
        }
        
      }
      return 
    }

    let oldValues = getPrevValues(this.props.answers[0].answer.columns.column1);
    console.log("shuffle", oldValues, this.props.answers[0].answer.columns);

    return (
      <RenderShuffle
        columnNames={this.props.answers[0].answer.columnnames}
        tasks={oldValues || this.props.answers[0].answer.columns}
        setShuffleValues={(shuffleValue) => {
          this.setShuffleValues(key1, shuffleValue);
        }}
      />
    );
  };

  renderForm = () => {
    // console.log("inside render");
    let model = this.props.model;
    // let model = getJsonWithEmptyValueUpdate();
    // console.log("model", model);
    let defaultValues = this.props.defaultValues;
    let formUI = model.map((m, i) => {
      // console.log("m", m)
      let key = m.key;
      let type = m.type || "text";
      let props = m.props || {};
      let name = m.name;
      let value = m.value;
      let index = i + 1;

      // console.log("value", value);

      let target = key;
      value = this.state[target] || value;

      // console.log("value", typeof value);
      let input = (
        <input
          {...props}
          className="input"
          type={type}
          key={key}
          name={name}
          value={value}
          aria-label={m.label}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={(e) => {
            this.onChange(e, target);
          }}
        />
      );

      if (type == "radio") {
        // console.log("m.options", m.options);
        input = m?.options?.map((o) => {
          let checked = o.value == value;
          return (
            <div className="form-group-input-label" key={"fr" + o.key}>
              <input
                {...props}
                className="form-input"
                type={type}
                key={o.key}
                name={o.name}
                checked={checked}
                value={o.value}
                aria-label={m.label}
                onChange={e => {
                  this.onChange(e, o.name);
                }}
              />
              <label aria-label={o.label} key={"ll" + o.key}>{o.label}</label>
            </div>
          );
        });
        input = <div className="form-group-radio">{input}</div>;
      }

      if (type == "select") {
        input = m?.options?.map((o) => {
          let checked = o.value == value;
          return (
            <option
              {...props}
              className="form-input"
              key={o.key}
              value={o.value}
            >
              {o.value}
            </option>
          );
        });

        //console.log("Select default: ", value);
        input = (
          <select
            value={value}
            onChange={(e) => {
              this.onChange(e, m.key);
            }}
          >
            {input}
          </select>
        );
      }

      if (type == "checkbox") {
        input = m?.options?.map((o) => {
          //let checked = o.value == value;
          let checked = false;
          if (value && value.length > 0) {
            checked = value.indexOf(o.value) > -1 ? true : false;
          }
          //console.log("Checkbox: ", checked);
          return (
            <div className="form-group-input-label" key={"cfr" + o.key}>
              <input
                {...props}
                className="form-input"
                type={type}
                key={o.key}
                name={o.name}
                checked={checked}
                value={o.value}
                aria-label={m.label}
                onChange={e => {
                  this.onChange(e, m.key, "multiple");
                }}
              />
              <label htmlFor={o.label} aria-label={o.label} key={"ll" + o.key}>{o.label}</label>
            </div>
          );
        });

        input = <div className="form-group-checkbox">{input}</div>;
      }
      if (type === "Table") {
        input = this.renderTable(m, index, m.key);
      }
      if (type == "rearrange") {
        input = this.renderRearrange(m.options, index, m.key);
      }
      if (type == "MultiListView") {
        console.log("multiolist view", m.key);
        input = this.renderMultiList(m.options, index, m.key);
      }
      if (type === "MultiListShuffle" || type === "Reorder") {
        console.log("shuffle", m.key);
        input = this.handleRenderShuffle(m.options, index, m.key, type);
      }
      if (type === "Matchpairs") {
        console.log("shuffle", m.key);
        input = this.handleRenderMatchPairs(m.options, index, m.key);
      }


      return (
        <div key={"g" + key} className="form-group">
          <label className="form-label" key={"l" + key} htmlFor={m.label}>
            {`${index})  ${m.label}`}
          </label>
          {input}
        </div>
      );
    });
    return formUI;
  };

  evaluationSubmit = () => {
    return (
      <>
        <div className="pre-next">
          {!this.props.isFirstStep ? (
            <button
              className="link-button small"
              onClick={() => {
                this.toEvaluate();
                // ToEvaluate()
                // this.updateValue();
                this.props.back();
              }}
            >
              <i class="fa-solid fa-chevron-left"></i>
              <span style={{ marginLeft: "5px" }}>Previous</span>
            </button>
          ) : (
            <button className="link-button small disabled">
              <i class="fa-solid fa-chevron-left"></i>
              <span style={{ marginLeft: "5px" }}>Previous</span>
            </button>
          )}
          {!this.props.isLastStep ? (
            <button
              className="link-button small"
              onClick={() => {
                this.toEvaluate();

                this.props.next();
              }}
            >
              <span style={{ marginRight: "5px" }}>Next</span>
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          ) : (
            <button className="link-button small disabled">
              <span style={{ marginRight: "5px" }}>Next</span>
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          )}
          {!this.props.isLastStep ? (
            <>
              <button
                onClick={() => {
                  // this.toEvaluate();
                }}
                type="button"
                // id="button-1"
                // disabled
                className="button primary small disabled"
              >
                Finish
              </button>
            </>
          ) : (
            <button
              type="button"
              // id="button-1"
              // disabled
              className="button primary small"
              onClick={() => {
                let finished = true;
                this.clearTimer(parseInt(sessionStorage.getItem("timeid")));
                this.toEvaluate(finished);
              }}
            >
              Finish
            </button>
          )}
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <div className="form-actions">
          <div className="form-actions-btn-title">
            <h4 class="form-actions-title">
              Answer the following
            </h4>
            {this.evaluationSubmit()}
          </div>
        </div>
        <div className="form-render evalindex">
          <div className={this.props.className}>
            <form
              id="my-form"
              className="dynamic-form"
              onSubmit={(e) => {
                this.onSubmit(e);
              }}
            >
              {this.renderForm()}
            </form>
          </div>
        </div>
        {/* <div className="form-actions">{this.evaluationSubmit()}</div> */}
      </>
    );
  }
}
