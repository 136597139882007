import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Column from './Column'; // Import your column component

const SqlDrag = ({data,setRenderSqlArray}) => (

    <>
  <DndProvider backend={HTML5Backend}>
    <Column data={data} setRenderSqlArray={setRenderSqlArray}/>
  </DndProvider>
  </>

);

export default SqlDrag;
