import { dateTimeHelper } from "../../../UtilityFunctions/dateHelper";
import useExpandableRowData from "./useExpandableRowData";
import LoaderUtility, {
  ClipLoaders,
} from "../../../UtilityFunctions/Loader_utility";
// import libraries
import * as XLSX from 'xlsx';
import axios from 'axios'
import { CSVLink } from "react-csv";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
// import styles
import "./expandablerow.scss";
import { useState } from "react";
import { fetchData } from "../../../ApiUtils/fetchData";
import ColumnGraph from "../../../Report/ColumnGraph";
import { IoIosArrowUp } from "react-icons/io";
import { VscGraph } from "react-icons/vsc";
import Table from "../../../UtilityFunctions/Table";
import AnswerPreview from "../../../PdfGenerator/AnswerSheet";
import Answerdata from "./Answerdata";
import { getValueFromSession } from "../../../UtilityFunctions/getSetValueSession";
import ApiConfig from "../../../../ApiConfig";
const ExpandableRow = ({
  cellId,
  testName,
  title,
  handleMarkTestComplete,
  upsloading,
  testTitle,
}) => {
  // console.log("cellId", cellId)
  const { respData, loading } = useExpandableRowData(cellId);
  const [isExpand, setIsExpand] = useState(false);
  const [index2, setIndex] = useState(0);
  const [yData, setYData] = useState([]);
  const [xData, setXData] = useState([]);
  const [colors, setColors] = useState([]);
  const [tableData, setTableData] = useState({});
  const [isLoading, setIsLaoding] = useState(false);
  const [tabledata, setTableData2] = useState([]);

  const headers = [
    { id: "1", field: "exid", headerName: "Exid" },
    // {id :'1', field:'title', headerName:'Title'},
    { id: "2", field: "subcategoryid", headerName: "Topic" },
    // {id :'2', field :'category', headerName :'Category' },
    { id: "3", field: "level", headerName: "Level" },
    { id: "4", field: "description", headerName: "Description" },
    { id: "5", field: "max", headerName: "No. of correct" },
    { id: "6", field: "questions", headerName: "No. of questions" },
  ];
  const headers2 = [
    { id: "1", field: "exid", headerName: "Exid" },
    // {id :'1', field:'title', headerName:'Title'},
    { id: "2", field: "subcategoryid", headerName: "Topic" },
    // {id :'2', field :'category', headerName :'Category' },
    { id: "3", field: "level", headerName: "Level" },
    { id: "4", field: "description", headerName: "Description" },
    { id: "5", field: "min", headerName: "No. of correct" },
    { id: "6", field: "questions", headerName: "No. of questions" },
  ];

  function getColors(correct, max, min, avg) {
    // console.log(correct, max, min, avg)
    let color = "";
    if (correct <= min && min !== max) {
      color = "#ff6178";
    } else if (correct < avg && correct > min) {
      color = "#febc3b";
    } else if (correct == 0 && avg == 0 && max == 0) {
      color = "#ff6178";
    } else if (correct >= avg && correct <= max && max !== 0 && avg !== 0) {
      color = "#13d8aa";
    } else {
      color = "#33b2df";
    }
    // console.log(color)
    return color;
  }

  function findMaxMin(data, xDataWithOriginalExid) {
    function mapCalback(item) {
      let correct = item.correct;
      let questions = item.questions;

      if ((correct * 100) / questions >= 75) {
        let exid =
          process.env.REACT_APP_USE_EXID_IN_COLUMN_CHART === "false"
            ? item.exid
            : `Ex - ${xDataWithOriginalExid.indexOf(item.exid) + 1}`;

        data2.push({
          exid,

          title: item.title,
          max: item.correct,
          questions: item.questions,
          subcategoryid: item.subcategoryid,
          // category:item.category,
          level: item.level,
          description: item.description?.slice(0, 100),
          percentage: (correct * 100) / questions,
        });
      } else if (
        (correct * 100) / questions < 40 ||
        (parseInt(item.correctmin) >= correct &&
          item.correctmin !== item.correctmax)
      ) {
        let exid =
          process.env.REACT_APP_USE_EXID_IN_COLUMN_CHART === "false"
            ? item.exid
            : `Ex - ${xDataWithOriginalExid.indexOf(item.exid) + 1}`;

        data3.push({
          exid,
          title: item.title,
          min: item.correct,
          questions: item.questions,
          subcategoryid: item.subcategoryid,
          level: item.level,
          description: item.description?.slice(0, 100),
          percentage: (correct * 100) / questions,
        });
      }
    }

    let data2 = [];
    let data3 = [];
    let dataLen = data?.length;
    let howManyMinMax = Math.min(dataLen / 3, 5);

    data?.map(mapCalback);
    data2?.sort((a, b) => {
      if (a.percentage === b.percentage) {
        return b.max - a.max;
      } else {
        return b.percentage - a.percentage;
      }
    });
    data3.sort((a, b) => {
      if (a.percentage === b.percentage) {
        return a.min - b.min;
      } else {
        return a.percentage - b.percentage;
      }
    });
    console.log(data);

    setTableData({
      maxTable: data2.filter((item, index) => index < howManyMinMax),
      minTable: data3.filter((item, index) => index < howManyMinMax),
    });
    return;
  }

  const getUserReportData1 = async (userid, scheduleid, index) => {
    let data = await fetchData(
      `getuserinfoforreport?scheduleid=${scheduleid}&userid=${userid}`
    );
    setTableData2(data?.data);
  };

  const extractExercises = (data) => {
    const exercises = [];
  
    data.forEach((item) => {
      const { testdetailsjson } = item;
  
      if (testdetailsjson) {
        const testDetails = JSON.parse(testdetailsjson);
        testDetails.forEach((detail) => {
          exercises.push(detail.exercise);
        });
      }
    });
  
    return exercises;
  };

  async function getQuestionsDetails(exidarray) {
    let Api=new ApiConfig()
    let response=await axios.get(Api.addApiKey(`${Api.BaseURI}/getQuestiondetails?exidarray=${exidarray}`));
     return response.data

  }



  function handelDetailReport(data) {

console.log("ddta")
    const FindAttempted = (item,targetArray) => {
      const exid = item.exid;
      const key=item.key
      return targetArray.some(obj => obj[exid] && obj[exid].some(entry => entry.key === exid+''+key));
    };
    
    const FindUserResponse = (item,target) => {
      const exid = item.exid;
      const key = item.key;
    
      const obj = target.find(obj => obj[exid]);
      if (obj && obj[exid]) {
        const response = obj[exid].find(entry => entry.key === exid+''+key);
        return response ? response.value : "No Response";
      }
      return "No Response";
    };
    
    const FindMatchOrNot = (item,target) => {
      const userResponse = FindUserResponse(item,target);
   
      return userResponse === item.answer ? "1" : "0";
    };







    const createExcelWithSheets = async (data) => {
      const workbook = XLSX.utils.book_new();

      let question_details = await getQuestionsDetails(extractExercises(data));
      data.forEach((item) => {
        console.log("ITEM",item)
        const { userid, scheduleid, testdetailsjson ,endtime,starttime,score,title,lastname,firstname} = item;

        const sheetData = [];
        sheetData.push(["Schedule ID:", scheduleid]);
        sheetData.push([]);
        sheetData.push(["Test Name:", title]);
        sheetData.push(["Candidate Name",firstname+' '+lastname]);
        sheetData.push(["Score:", score]);
        sheetData.push(["Start time:", starttime]);
        sheetData.push(["End time:", endtime]);
        let anserarray = []
        if (testdetailsjson) {
          sheetData.push([]);
          sheetData.push(["Test Details"]);
          sheetData.push(["Exercise", "Correct", "Questions", "Max Score", "Attempted", "Not Attempted"]);

          const testDetails = JSON.parse(testdetailsjson);


          testDetails.forEach((detail) => {
            anserarray.push({
              [detail.exercise]: detail?.answers?.map((answer) => ({
                key: answer.key,
                value: answer.value
              }))
            });
            // anserarray = [...anserarray, ...detail.answers.map((answer) => `${answer.key}`: `${JSON.stringify(answer.value)``})];
            // anserarray=anserarray.push({})

            sheetData.push([
              detail.exercise,
              detail.correct,
              detail.questions,
              detail.maxscore,
              detail.attempted,
              detail.notAttempted
            ]);
          });

        }
        if (question_details) {
          sheetData.push([]);
          sheetData.push([]);
          sheetData.push([]);
          sheetData.push(["Questions Details"]);
          sheetData.push(["Exid", "Key", "Title", "Attempted","Answer",'Userresponse', "Score"]);
          question_details.map((item) => {

            sheetData.push([item.exid, item.key, item.label, FindAttempted(item,anserarray), item.answer,FindUserResponse(item,anserarray),FindMatchOrNot(item,anserarray)])
          })
        }



        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

        XLSX.utils.book_append_sheet(workbook, worksheet, userid.substring(0, 31));
      });

      XLSX.writeFile(workbook, `AllUsers.xlsx`);
    };

    return (
      <>
        <button className="button primary" onClick={(e) => { createExcelWithSheets(data) }}>
          Download Details
        </button>
      </>
    )
  }

  const getUSerReportData = async (userid, scheduleid, index) => {
    setIsLaoding(true);
    // setIsExpand(!isExpand)
    setIndex(index);
    setColors([]);
    setYData([]);
    setXData([]);
    let data = await fetchData(
      `getuserinfoforreport?scheduleid=${scheduleid}&userid=${userid}`
    );
    console.log("tabledatais###", data);
    let xDataWithOriginalExid = [];
    data?.data?.map((item, index) => {
      // colors.push(getColors(parseInt(item.correct),item.correctmax,item.correctmin,item.correctavg))

      setYData((prev) => [...prev, item]);
      // setXData(prev=>[...prev,item.exid])
      process.env.REACT_APP_USE_EXID_IN_COLUMN_CHART === "false"
        ? setXData((prev) => [...prev, item.exid])
        : setXData((prev) => [...prev, `Ex - ${index + 1}`]);
      xDataWithOriginalExid.push(item.exid);
      // setXData(prev=>[...prev,`Ex - ${index+1}`])
      // console.log('item  $$$',item)
      setColors((prev) => [
        ...prev,
        getColors(
          parseInt(item.correct),
          item.correctmax,
          item.correctmin,
          item.correctavg
        ),
      ]);
    });

    setTableData(data?.data);
    findMaxMin(data?.data, xDataWithOriginalExid);
    setIsLaoding(false);
  };

  // useEffect(()=>{
  //   getUSerReportData()
  // },[isExpand])
  const settings = getValueFromSession("settings");

  const handleDownload = () => {
    const expandedRowData = respData || []; // Get expanded row data
    const headers = [
      "Userid",
      "No of correct answers",
      "No of questions",
      "Score",
      "Total marks",
    ];
    if (JSON.parse(settings).percentile === true) {
      headers.push("Percentile");
    }
    headers.push("Start time", "End time");
    // console.table(expandedRowData)
    const csvData = [
      ["Test Name", testName],
      ["Title", testTitle],
      ["Scheduleid", cellId],
      ["Total participants", expandedRowData?.length],
      [" ", " "],
      headers, // Include headers

      ...expandedRowData.map((row) => {
        const rowData = [
          row.userid,
          row.no_of_correct_answers,
          row.no_of_questions,
          row.score,
          row.totalmarks,
        ];
        if (JSON.parse(settings).percentile === true) {
          rowData.push(Math.round(row.Percentile));
        }
        rowData.push(dateTimeHelper(row.localstarttime, true, true));
        rowData.push(dateTimeHelper(row.localendtime, true, true));
        return rowData;
      }),
    ];

    return (
      <CSVLink data={csvData} filename={"Results.csv"}>
        <button className="link-button">Download CSV</button>
      </CSVLink>
    );
  };

  return (
    <>
      <section className="expandablerow">
        {loading ? (
          <LoaderUtility loading={loading} />
        ) : (
          <section className="expandablerow_download_table">
            
            <section className="expandablerow_download_actions">

             {process.env.REACT_APP_SHOW_DETAIL_RESULT_BUTTON=='true' &&  handelDetailReport(respData)}
               {/* <Link className="link-button" to={`/detailreport`}>
                Detail Result 

               </Link> */}


              {process.env.REACT_APP_SHOW_REPORT === "true" && (
                <Link
                  className="link-button"
                  to={`/report?scheduleid=${cellId}`}
                >
                  Detailed report
                </Link>
              )}
              {process.env.REACT_APP_SHOW_REPORT === "true" && (
                <Link
                  className="link-button"
                  to={`/insights?scheduleid=${cellId}`}
                >
                  Insights
                </Link>
              )}
              {handleDownload()}

              {title === "Ongoing Test" && (
                <button
                  onClick={() => handleMarkTestComplete("completed", cellId)}
                  className="button complete small actions"
                >
                  Mark Complete
                  {upsloading ? (
                    <ClipLoaders loading={upsloading} />
                  ) : (
                    <IoCheckmarkDoneSharp
                      size={20}
                      color="#FFF"
                      cursor={"pointer"}
                    />
                  )}
                </button>
              )}
            </section>
            <section className="expandablerow_table table-container">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Userid</th>
                    <th>Scheduleid</th>
                    <th>No of correct answers</th>
                    <th>No of questions</th>
                    <th>Score</th>
                    <th>Total marks</th>
                    {JSON.parse(settings).percentile === true && (
                      <th>Percentile</th>
                    )}
                    {process.env.REACT_APP_SHOW_REPORT === "true" && (
                      <th>Performance graph</th>
                    )}
                    {process.env.REACT_APP_SHOW_QUESTION_PEPER == "true" && (
                      <th>Answer paper</th>
                    )}
                    {process.env.REACT_APP_SHOW_RESULT_BREAKDOWN=='true' &&(
                      <th>Score breakdown</th>
                    )
                    }
                    <th>Start time</th>
                    <th>End time</th>
                  </tr>
                </thead>
                <tbody>
                  {checkDataExists(
                    respData,
                    isExpand,
                    setIsExpand,
                    yData,
                    xData,
                    setYData,
                    setXData,
                    colors,
                    setColors,
                    index2,
                    setIndex,
                    getUSerReportData,
                    isLoading,
                    tableData,
                    headers,
                    headers2,
                    settings
                  )}
                </tbody>
              </table>
            </section>
          </section>
        )}
      </section>
    </>
  );
};

export default ExpandableRow;

export const checkDataExists = (
  data,
  isExpand,
  setIsExpand,
  yData,
  xData,
  setYData,
  setXData,
  colors,
  setColors,
  index2,
  setIndex,
  getUSerReportData,
  isLoading,
  tableData,
  headers,
  headers2,
  settings
) => {
  // console.log("ExpandableRow", tableData)
  if (data) {
    if (data.length > 0) {
      {
        return data.map((cell, index) => (
          <>
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{cell.userid}</td>
              <td>{cell.scheduleid}</td>
              <td>{cell.no_of_correct_answers}</td>
              <td>{cell.no_of_questions}</td>
              <td>{cell.score}</td>
              <td>{cell.totalmarks}</td>
              {JSON.parse(settings).percentile === true && (
                <td>{Math.round(cell.Percentile)}</td>
              )}
              {process.env.REACT_APP_SHOW_REPORT === "true" && (
                <td
                  onClick={(e) => {
                    setIsExpand(!isExpand);
                    if (index !== index2 || !isExpand) {
                      getUSerReportData(cell.userid, cell.scheduleid, index);
                    }
                  }}
                >
                  <VscGraph size={25} title="performance graph" />
                </td>
              )}

              {process.env.REACT_APP_SHOW_QUESTION_PEPER == "true" && (
                  <td>
                      <AnswerPreview data={cell} />
                  </td>
              )}

              {process.env.REACT_APP_SHOW_RESULT_BREAKDOWN == "true" && (
                <td>
                  <Answerdata
                    userid={cell.userid}
                    scheduleid={cell.scheduleid}
                    index={index}
                  />
                </td>
              )}
              <td>{dateTimeHelper(cell.localstarttime, true, true)}</td>
              <td>{dateTimeHelper(cell.localendtime, true, true)}</td>
            </tr>
            {
              isExpand && index === index2 && (
                //  <div>

                <tr
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  <td
                    colSpan={5}
                    style={{
                      pointerEvents: "all",
                      backgroundColor: "white",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        float: "right",
                        // pointerEvents:'all'
                        zIndex: "100",
                      }}
                    >
                      <IoIosArrowUp
                        title="Collapse"
                        size={20}
                        onClick={() => {
                          console.log("onclick");
                          setIsExpand(false);
                        }}
                      />
                    </div>
                    <ColumnGraph
                      xData={xData}
                      yData={yData}
                      colors={colors}
                      isLoading={isLoading}
                    />
                  </td>
                  {/* <td></td> */}
                  <td
                    colSpan={6}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "white",
                      zIndex: 3,
                    }}
                  >
                    <div
                      style={{
                        pointerEvents: "none",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <div className="table-header-div">
                        <h3>High scoring exercises</h3>
                        <hr />
                        {/* <section className="table-container"> */}

                        <Table
                          headers={headers}
                          rows={tableData?.maxTable}
                          isLoading={isLoading}
                          isCheckBox={false}
                        />
                        {/* </section> */}
                      </div>

                      <div className="table-header-div">
                        <h3>Low scoring exercises</h3>
                        <hr />
                        {/* <section className="table-container"> */}

                        <Table
                          headers={headers2}
                          rows={tableData?.minTable}
                          isLoading={isLoading}
                          isCheckBox={false}
                        />
                        {/* </section> */}
                      </div>
                    </div>
                  </td>
                </tr>
              )
              // </div>
            }
          </>
        ));
      }
    } else {
      return (
        // <tbody>
        <td colSpan={JSON.parse(settings).percentile === true ? "12" : "11"}>
          <div className="pyyi_table-zerostate">No Result found!</div>
        </td>
        // </tbody>
      );
    }
  }
};
