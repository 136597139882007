import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";

export function Container({ id, tasks }) {
  const { setNodeRef, isOver } = useDroppable({ id });

  const containerClass = isOver
    ? "shuffle-container drag-over"
    : "shuffle-container";

  return (
    <SortableContext
      id={id}
      items={tasks?.map((task) => task.id)}
      strategy={verticalListSortingStrategy}
    >
      <div
        ref={setNodeRef}
        className={containerClass}
      >
        {tasks?.map((task) => (
          <SortableItem
            key={task.id}
            id={task.id}
            description={task.description}
          />
        ))}
      </div>
    </SortableContext>
  );
}
