import MatchPairs from "./MatchPairs";

export default function RenderMatchPairs({ columns, setMatchPairsValues, userAns }) {
  return (
    <MatchPairs
    userAns ={userAns}
      setMatchPairsValues={setMatchPairsValues}
      columns={columns}
    />
  );
}
