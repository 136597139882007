export function getAnswersFromType(answer) {
  const types = [
    "Table",
    "MultiListView",
    "MultiListShuffle",
    "Reorder",
    "Matchpairs",
    "rearrange",
  ];
  const descodedAnswer = atob(answer.answer);
  console.log("descodedAnswer", descodedAnswer);
  console.log("descodedAnswer", types.includes(answer.type), answer, types);
  if (types.includes(answer.type)) {
    console.log("descodedAnswer", JSON.parse(descodedAnswer));
    console.log("descodedAnswer", typeof descodedAnswer);
    return JSON.parse(descodedAnswer);
  }
  // else if (answer.type === "Shuffle") return data;
  return descodedAnswer;
}

const data = {
  tasks: [
    {
      id: "1",
      description: "Submerge the perforated plate in the solution.",
      status: "ORIGINAL",
    },
    {
      id: "2",
      description:
        "Measure 5 ml of 0.01% methylene blue solution and add it to the desiccator.",
      status: "ORIGINAL",
    },
    {
      id: "3",
      description:
        "Fill the desiccator with purified water up to the perforated plate (5 liters).",
      status: "ORIGINAL",
    },
    {
      id: "4",
      description: "Clean the desiccator using purified water.",
      status: "ORIGINAL",
    },
  ],
  answers: [4, 3, 2, 1],
};
