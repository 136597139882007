import Shuffle3 from "./Shuffle3";

export const RenderShuffle = ({ columnNames, tasks, setShuffleValues }) => {
  console.log("shuffle ty", typeof tasks);
  console.log("shuffle t", tasks);

  return (
    <Shuffle3
      columnNames={columnNames}
      INITIAL_TASKS={tasks}
      setShuffleValues={setShuffleValues}
    />
  );
};
