export default function gettitleLHS(track, module, type) {
  let codelanguage = ["C", "Python", "Javascript"];

  if (codelanguage.includes(track)) {
    if (module == "debug" || module == "solver") {
      return "Code Editor";
    } else {
      return "Code Viewer";
    }
  }

  else if(track=='Visual Programming'){
    return 'Flowchart viewer'
  }
  
  else if (track == "Basic Electronics") {
    return "Circuit Diagram";
  } 
  else if (track == "Mathematics Skills" ) {
    return "Read the problem statement";
  } 
  
  else if (track == "Communication Skills") {
    return "Read the passage";
  }
  else if (track == "My Exercises") {
    if (module === 'Cognitive Links')
      return "Dashboard Viewer"
    else 
    {
        if (type === 'image')
          return "Circuit Diagram"
        else 
          return  "Read the problem statement";

    }
     
  }
  else{
    return "Code viewer"
  }
}
