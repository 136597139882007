import { findContainer } from "./findContainer";

export function handleDragOver(event, setTasks, tasks) {
  const { active, over } = event;
  const activeId = active?.id;
  const overId = over?.id;

  if (!overId || activeId === overId) return;

  const activeContainer = findContainer(activeId, tasks);
  const overContainer = findContainer(overId, tasks);

  console.log("activeContainer", activeContainer);
  console.log("activeContainer", overContainer);

  if (!activeContainer || !overContainer || activeContainer === overContainer)
    return;

  setTasks((prev) => {
    console.log("prev", prev);
    const activeItems = prev[activeContainer];
    const overItems = prev[overContainer];

    const activeTaskIndex = activeItems.findIndex(
      (item) => item.id === activeId
    );
    const overTaskIndex = overItems.findIndex((item) => item.id === overId);

    const activeTask = activeItems[activeTaskIndex];

    // Remove from the active container
    const updatedActiveItems = activeItems.filter(
      (_, index) => index !== activeTaskIndex
    );

    // Add to the over container
    const updatedOverItems = [
      ...overItems.slice(0, overTaskIndex + 1),
      activeTask,
      ...overItems.slice(overTaskIndex + 1),
    ];

    return {
      ...prev,
      [activeContainer]: updatedActiveItems,
      [overContainer]: updatedOverItems,
    };
  });
}
