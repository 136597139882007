export function getLangTitleAndShortName(lang, module, category) {
  let tracks = sessionStorage.getItem('tracks')?JSON.parse(sessionStorage.getItem('tracks')) : []
  console.log("track%%", tracks)
  console.log("lang", lang)
  let title = ''
  let shortname = ''
  if (tracks) {
    if (lang === 'My Exercises')
    {
        if (module === 'Cognitive Links')
        {
          title = 'Cognitive Links';
          shortname = 'CL';
        } else if (module === 'VidyaLeap')
        {
          title = category;
          shortname = category.split(' ').map(item=>item[0].toUpperCase()).join('');
        }


        return { title: title, shortname: shortname }
    }

    for (var i = 0; i < tracks.length; i++) {

      if (tracks[i].track === lang) {
        title = tracks[i].title;
        shortname = tracks[i].shortname
      }
    }
  }
  return { title: title, shortname: shortname }
}