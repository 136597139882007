import { DragOverlay } from "@dnd-kit/core";
import { Item } from "./Item";
import { findContainer } from "./utils/findContainer";

export function Overlay({ activeId, tasks }) {
  if (!activeId) return null;

  const container = findContainer(activeId, tasks);
  const activeTask = tasks[container]?.find((task) => task.id === activeId);

  return (
    <DragOverlay
      dropAnimation={{
        duration: 500,
        easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
      }}
    >
      {activeTask ? (
        <Item
          id={activeTask.id}
          description={activeTask.description}
        />
      ) : null}
    </DragOverlay>
  );
}
