  import React, { useRef, useState } from "react";
  import Drawer from "../../CustomDrawer/Drawer";
  import Table from "../../UtilityFunctions/Table";
  import { BiReset } from "react-icons/bi";
  import { ToastContainer, toast } from "react-toastify";
  import LoaderUtility from "../../UtilityFunctions/Loader_utility";
  import { postData } from "../../ApiUtils/postData";
  import { getValueFromSession } from "../../UtilityFunctions/getSetValueSession";
  import AddUserSchedule from "../../Event/AddUserToSchedule";
  import UpdateUserDetails from "./UpdateUserDetails";


  export default function ViewUserDetails({
    userDetails,
    isShowDetails,
    setIsShowDetails,
    headers,
    isLoading,
    title,
    getUserdetails,
  }) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [resetLoading, setResetLoading] = useState(false);
    const [scheduleId, setScheduleId] = useState(null)
    const [showDialogupdate,setshowDialogupdate]=useState(false)
    let showReset =
      title === "Ongoing Test" && process.env.REACT_APP_USE_RESET_TEST === "true";
    let showAddUsersToSchedule= title === 'manageevent'
      const toastRef = useRef()
    const resetUserTest = async () => {
      if(selectedRows.length <=0){
        
        if (!toast.isActive(toastRef.current)) {
          toastRef.current = toast.error("Please select user", {
            position: "top-center",
          })
        }
        return
      }
      setResetLoading(true);
      let data = {
        useridArr: selectedRows.map((item) => item.userid),
        scheduleid: getValueFromSession("selectedscheduleId"),
        adminuserid:getValueFromSession('username')
      };
      console.log(data);
      let result = await postData("ressetusertest", data);
      console.log(result.data);
      getUserdetails(getValueFromSession("selectedscheduleId"));
      setResetLoading(false);
      setSelectedRows([])
    };

    return (
      <>
        {/* <button className='button secondary' onClick={getUserdetails}> Veiw details </button>  */}

        {isShowDetails && (
          <Drawer
            isOpen={isShowDetails}
            onClose={() => {
              setIsShowDetails(false);
              setSelectedRows([])
            }}
          >
              {/* <ToastContainer /> */}
            <div style={{
              display:'flex',
              flexDirection:'column'
            }}>
          <span  style={{display:'flex',marginLeft:'auto',marginRight:'1rem'}}>
             
       
            {showReset && (
              <div>

              <button
                style={{
                  float: "right",
                  margin: "0.0rem .6rem .5rem 0",
                }}
                title="Select and reset user test"
                className="button primary small"
                onClick={resetUserTest}
                >
                <BiReset size={18} />
              </button>
                </div>
            )}
            {showAddUsersToSchedule && (
              <div>
                    
                  <AddUserSchedule               
                  setScheduleId={setScheduleId}
                  selectedUsers={selectedRows}
                  setSelectedUsers={setSelectedRows}
                  userDetails={userDetails}
                  />
                </div>
            )}
             {process.env.REACT_APP_SHOW_UPDATE_DETAILS_BUTTON==="true" &&
            <button className="button primary small" onClick={()=>setshowDialogupdate(true)}>Update User Details</button>
              }
                    </span>
            {/* <div
      style={{
        backgroundColor:'white',
        width:'100%',
        height:'50%'
      }}
      > */}
            {isLoading ? (
              <LoaderUtility loading={isLoading} loadingMsg="Loading" />
            ) : (
            < >
            <div
              style={{display:'flex',alignItems:'center',marginBottom:'0.8rem'}}

           
            >
      
                
            <span
             
            > {selectedRows.length} row(s) selected of {userDetails.length} row(s)</span>
           
              </div>
              <section className="table-container">
          
            <div  style={{maxHeight:'25rem'}}>
              <Table
                rows={userDetails}
                headers={headers}
                data={selectedRows}
                setSelected={setSelectedRows}
                isCheckBox={showReset || showAddUsersToSchedule}
                noDataMsg={"No data available"}
                />
                </div>
                <div>
            { <UpdateUserDetails userDetails={userDetails} setshowDialogupdate={setshowDialogupdate} showDialogupdate={showDialogupdate}/>}
            </div>
                </section>
            </>
            )}
      </div>
            {/* </div> */}
          </Drawer>
        )}
      </>
    );
  }



