// Column.js
import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import DraggableItem from './DraggableItem';
import { ItemTypes } from './ItemTypes';

const Column = ({data,setRenderSqlArray}) => {
  const [items, setItems] = useState(data[0]?.Statement|| []);
  useEffect(() => {
    const newarray = [];
    items.forEach((item) => newarray.push(parseInt(item.key)));
    setRenderSqlArray(newarray);
  }, [items]);

  const moveItem = (dragIndex, hoverIndex) => {
    const newItems = [...items];
    const [draggedItem] = newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, draggedItem);
    setItems(newItems);
  };

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
  });

  return (
    <div ref={drop} style={{ width: '100%', height: '14rem', border: '1px solid black', padding: '10px', overflowY: 'auto' }}>
      {items.map((item, index) => (
        <DraggableItem
          key={item.id}
          id={item.id}
          text={item.text}
          index={index}
          moveItem={moveItem}
        />
      ))}
    </div>
  );
};

export default Column;
