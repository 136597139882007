import React from "react";
import Editor from "@monaco-editor/react";
import { BiPlay, BiCheckDouble } from "react-icons/bi";
import PulseLoader from "react-spinners/PulseLoader";
import LeftHandside from "./Lefthandside";
import PrevNextButton from "../Evaluation/PrevNextButton";
import { RunTestBtnActivateInactivate } from "./RunTestBtnActivateInactivate";
import gettitleLHS from "./GetTitleforeditor";

const override = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
  // borderColor: "olive",
  // backgroundColor: "red",
  // width: "100%",
};

const renderSolverEditor = (props) => {
  let {
    isModal,
    setIsMOdal,
    isMarkComplete,
    submitting,
    submiited,
    optionsD,
    code,
    setCode,
    setIsMarkComplete,
    change,
    compile,
    isCompletedExid,
    sessionStorage,
    markComplete,
    isLimitExceed,
    AiOutlineWarning,
    Tabs1,
    ShowConfetti,
    coinMsg,
    Confetti,
    ConfettiExplosion,
    Congrats,
    debugcode,
    setIsLimitExceed,
    setResult,
    setOutput,
    setChange,
    testLoader,
    setTestLoader,
    setSubmitMsg,
    toFinishEvaluate,
    randomexe,
    fromPagi,
    currentStepIndex,
    setCurrentStepIndex,
    isLoading,
    npvalue,
    step,
    evaluate,
    handlesetAttNtAttp,
    setIsLoading,
    isFirstStep,
    isLastStep,
    output,
    numTests,
    score,
    totalMarks,
    no_of_correct,
    no_of_questions,
    resultarr,
    isEvaluation,
    module,
    language,
    showAttemptedNotAttempted,
    setIfAttempted
  } = props;

  console.log("fromPagi", fromPagi)
  console.log("resultarr", resultarr)
  return (
    <>
      {isEvaluation ? (
        <LeftHandside
          {...props}
          isNormal={true}
          code={debugcode}
          onChange={(value) => {
            setCode(value);
            RunTestBtnActivateInactivate(
              true,
              testLoader,
              setTestLoader,
              setChange,
              setOutput,
              setIsLimitExceed
            );
          }}
          title={gettitleLHS(language, 'debug')}

        />
      ) : (
        <LeftHandside
          {...props}
          isNormal={true}
          onChange={(value) => {
            setCode(value);
            RunTestBtnActivateInactivate(
              true,
              testLoader,
              setTestLoader,
              setChange,
              setOutput,
              setIsLimitExceed
            );
            setIsMarkComplete(false);
          }}
          title={gettitleLHS(language, module)}
        />
      )}

      {isEvaluation ? (
        <div className="questions">
          <div className="to-debugger-eval-quiz">
            <div className="to-debugger-eval-btn-limit">
              <button
                className={
                  change
                    ? "to-debugger main-link-debug "
                    : "to-debugger main-link-debug disabled"
                }
                onClick={() => {
                  compile(setResult, randomexe, currentStepIndex, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output)
                  setIfAttempted(true)
                }}
              >
                <BiPlay fontSize={20} />
                Run Tests
              </button>
              {isLimitExceed && (
                <div className="eval-run-btn-warning">
                  <AiOutlineWarning size={42} color="#ffcc00" />
                  <label>
                    You have reached the daily run test limit. Please try again
                    tomorrow.
                  </label>
                </div>
              )}
            </div>
            <div className="pre-next">
              <PrevNextButton
                showAttemptedNotAttempted={showAttemptedNotAttempted}
                setResult={setResult}
                setIsMOdal={setIsMOdal}
                setSubmitMsg={setSubmitMsg}
                toFinishEvaluate={toFinishEvaluate}
                exdatarr={randomexe}
                newcurrent={currentStepIndex}
                currentStepIndex={currentStepIndex}
                setCurrentStepIndex={setCurrentStepIndex}
                loading={isLoading}
                setIsLoading={setIsLoading}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                resultarr={resultarr}
                debugcode={debugcode}
                no_of_correct={no_of_correct}
                no_of_questions={no_of_questions}
                totalMarks={totalMarks}
                score={score}
                numTests={numTests}
                output={output}
                handlesetAttNtAttp={handlesetAttNtAttp}
                npvalue={npvalue}
                step={step}
                evaluate={evaluate}
                testLoader={testLoader}

              />
            </div>
          </div>
          <div className="tab-debug">{Tabs1()}</div>
        </div>
      ) : (
        <div className="questions">
          <div className="btn_msg_container">
            <div className="to-debugger">
              <button
                className={
                  change
                    ? "to-debugger main-link-debug"
                    : "to-debugger main-link-debug disabled"
                }
                onClick={compile}
                disabled={!change}
              >
                <BiPlay fontSize={20} />
                Run Tests2
              </button>

              {!isCompletedExid &&
                sessionStorage.getItem("status") !== "completed" && (
                  <button
                    type="button"
                    className={
                      isMarkComplete
                        ? "mark-button-debug"
                        : "mark-button-debug disabled"
                    }
                    onClick={() => {
                      markComplete();
                    }}
                    disabled={!isMarkComplete}
                  >
                    <BiCheckDouble fontSize={20} />
                    Mark Complete
                  </button>
                )}

              {sessionStorage.getItem("status") === "completed" ||
                isCompletedExid ? (
                <label className="completed-button disabled">Completed</label>
              ) : null}
            </div>

            <div className="run-btn-warning">
              {isLimitExceed && (
                <>
                  <AiOutlineWarning size={42} color="#ffcc00" />
                  <label>
                    You have reached the daily run test limit. Please try again
                    tomorrow.
                  </label>
                </>
              )}
            </div>
          </div>

          <div className="tab-debug">{Tabs1()}</div>

          {ShowConfetti && (
            <>
              {coinMsg.includes("coins") &&
                coinMsg.includes("badge points") && (
                  <div>
                    <Confetti
                      width={window.innerWidth}
                      height={window.innerHeight}
                      numberOfPieces={500}
                    />
                  </div>
                )}
              <div className="celebDiv">
                <div className="message-container">
                  {coinMsg.includes("coins") &&
                    coinMsg.includes("badge points") && (
                      <ConfettiExplosion zIndex={12} />
                    )}
                  <Congrats msg={coinMsg} />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default renderSolverEditor;
