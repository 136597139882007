import { arrayUtils } from "utils/arrayUtils";
import { equals } from "utils/comparisons";
import { evaluateTrueFalse } from "utils/evaluateTrueFalse";
import { getLength } from "utils/getLength";

export function handleGetIds(columnNames, tasks) {
  const size = getLength(columnNames);
  const isEquals = equals(size, 1);
  const column = evaluateTrueFalse(isEquals, tasks.column1, tasks.column2);
  const taskIds = arrayUtils.mapItems(column, (n) => n.id);
  return taskIds;
}
