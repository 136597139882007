import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Item } from "./Item";

export function SortableItem({ id, description }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
  };

  const itemClass = isDragging ? "sortable-item dragging" : "sortable-item";

  return (
    <div
      className={itemClass}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Item
        id={id}
        description={description}
      />
    </div>
  );
}
