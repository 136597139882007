function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    arr1 = convertObjectValuesToString(arr1);
    arr2 = convertObjectValuesToString(arr2);

    const copyArr1 = [...arr1];
    const copyArr2 = [...arr2];

    for (let obj1 of copyArr1) {
        const index = copyArr2.findIndex(obj2 => isObjectEqual(obj1, obj2));

        if (index === -1) {
            return false;
        }

        copyArr2.splice(index, 1);
    }

    return true;
}


function isObjectEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1).sort(); 
    const keys2 = Object.keys(obj2).sort(); 

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

function convertObjectValuesToString(arr) {
    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i];

        for (let key in obj) {
            obj[key] = String(obj[key]);
         
        }
    }
    return arr; 
}
export default areArraysEqual;