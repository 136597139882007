import { useState } from 'react';
import { postData } from '../ApiUtils/postData'
import { getValueFromSession } from '../UtilityFunctions/getSetValueSession'
import { validTillTimeString } from '../Schedule/scheduleutils';

const useUpdateScheduleTest = () => {
  const [upsloading, setLoading] = useState(false);
  const [updateMsg, setUpdateMsg]=useState('')
  const UpdateScheduleTest = async (scheduleid, scheduletime, markingscheme, showresult, validtill, useridArr, issendmail, status, selectedUserids,removeUseridArr, title, endDateValue, endTime, isEndDateChanged, isEndTimeChanged) => {
    
    // console.log("scheduleid", scheduleid)
    // console.log("scheduletime", scheduletime)
    // console.log("markingscheme", markingscheme)
    // console.log("showresult", showresult)
    console.log("validTill",  endDateValue, endTime, isEndTimeChanged)
    // console.log("useridArr", useridArr)
    // console.log("issendmail", issendmail)
    // console.log("status", status)
    // console.log('removeUseridArr ',removeUseridArr)
    try {
      setLoading(true)
      if (endTime !== null && endDateValue !== null && endTime !== undefined && endDateValue !== undefined) {
        console.log('if ')
       let [combinedISOString, validTillISOString] = validTillTimeString(endTime, endDateValue );
        validtill=(isEndDateChanged || isEndTimeChanged)?combinedISOString : validTillISOString
        console.log('validtill',combinedISOString, validTillISOString)
      }else{
        console.log('else')
        validtill=validtill
      }
      console.log('validtill',validtill)

      const userid = getValueFromSession('username')
      let postdata = {
        scheduleid,
        userid,
        scheduletime,
        markingscheme,
        showresult,
        validtill,
        useridArr,
        issendmail,
        status,
        removeUseridArr,
        title,
        
      }
      const uri = `updateschedule`;
      const data = await postData(uri, postdata);
      console.log("response: ",data, data.data.message);
      setUpdateMsg( data?.data?.message)

    } catch (error) {
      console.log("error", error.message)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 1000);
    }
  }

  return { UpdateScheduleTest, upsloading, updateMsg }
}

export default useUpdateScheduleTest