import React, { useMemo, useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Timer from "react-countdown";
//components
import ApiConfig from "../../../ApiConfig";
import CustomizableRenderer from "../Eval_quiz_utility_functions/CustomizableRenderer ";
import DynamicForm from "./EvalForm";
import PulseLoader from "react-spinners/PulseLoader";
import "../Eval_quiz_utility_functions/MEditor.scss";
import "../Eval_quiz_utility_functions/DynamicForm.scss";
import {
  getValueFromSession,
  setValueInSession,
} from "../../UtilityFunctions/getSetValueSession";
import { RunTestBtnActivateInactivate } from "../Eval_quiz_utility_functions/RunTestBtnActivateInactivate";

import useQuesAnsAssertTest from "../Eval_quiz_utility_functions/Hooks/useQuesAnsAssertTest";
import { handleEditorDidMount } from "../Eval_quiz_utility_functions/handleEditorDidMount";
import { setResult } from "../Eval_quiz_utility_functions/SetResult";
import { useToEvaluateGrandTotal } from "../Eval_quiz_utility_functions/Hooks/useToEvaluateGrandTotal";
import { useCompile } from "../Eval_quiz_utility_functions/Hooks/useCompile";
import useSetResultArrOutputVal from "../Eval_quiz_utility_functions/Hooks/useSetResultArrOutputVal";
import useSetEvaluationQuizScore from "../Eval_quiz_utility_functions/Hooks/useSetEvaluationQuizScore";

import FullSizeImage from "../Eval_quiz_utility_functions/Fullsizeimage";
import TabsComponent from "../Eval_quiz_utility_functions/Tabcomponent";
import ModalComponent from "../Eval_quiz_utility_functions/Modal_component";
import { getLangTitleAndShortName } from "../../UtilityFunctions/getLangTitleAndShortName";
import PaginationEditor from "./PaginationEditor/Pagination";
import useEvalFormutils from "./PaginationEditor/useEvalFormutils";
import { Tooltip } from 'react-tooltip';


const EvalEditor = () => {
  const { pathname, state } = useLocation();
  window.history.pushState(null, "", pathname);
  window.onpopstate = function () {
    window.history.pushState(null, "", pathname);
  };

  let username = getValueFromSession("username");
  // const startTime = new Date(sessionStorage.getItem("start_time"));
  let duration = sessionStorage.getItem("duration");
  let language = sessionStorage.getItem("language")
  // let username = 'debug1@gmail.com';
  const startTime = new Date(sessionStorage.getItem("time"));
  // console.log("scheduleId", state)
  // console.log("scheduleId12", state?.testCardData?.scheduleid)
  const scheduleId = state?.testCardData?.scheduleid
  const showResult = state?.testCardData?.showresult
  // console.log("showResult: ", showResult)

  // const [modifyOutput, setModifyOutput] = useState(false);


  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  };
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(currentStepIndex);
  const [isLoading, setIsLoading] = useState(true);
  const [npvalue, statenp] = useState({})
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const [fromPagi, setFromPagi] = useState(false);
  const [timer, setTimer] = useState(
    parseInt(Math.ceil(duration / 5) * 5) * 60 * 1000 - (Date.now() - startTime)
  );
  const [userLang, setUserLang] = useState("c");
  const [isMarkComplete, setIsMarkComplete] = useState(false);
  const [isModal, setIsMOdal] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  // const [resultarr, setResultArr] = useState([]);
  const [correctAssert, setCorrectAssert] = useState(0);
  // const [count, setCount] = useState(1);
  const [change, setChange] = useState(false);
  // const [evaluate, setEvaluate] = useState([]);
  // const [isopen, setIsopen] = useState(false);
  const [submit_smg, setSubmitMsg] = useState(false);
  const [submit_loading, setSubmitLoading] = useState(false);
  // const [numTests, setNumTests] = useState(0);
  // const [questions, setQuestions] = useState([]);
  // const [answers, setAnswers] = useState([]);
  const [quesresp, setQuesResp] = useState("");
  // const [summarks, setSummarks] = useState(0);
  // const [sumques, setSumques] = useState(0);
  const [istimer, setIsTimer] = useState(false);
  // const [imgDimensions, setImgDimensions] = useState([]);
  // const [justifyCenter, setJustifyCenter] = useState(false);
  const [zoom, setZoom] = useState(1);
  // const [imageWidth, setImageWidth] = useState(false);
  const [isSlide, setIsSlide] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLimitExceed, setIsLimitExceed] = useState(false);
  const [testLoader, setTestLoader] = useState(false)
  // const [showRunTestCOunt, setShowRunTest] = useState(false)
  const [show5min, setShoe5Min] = useState(false)
  const [statusCode, setStatusCode] = useState(0);
  const [ifAttempted, setIfAttempted] = useState(false);

  const imageDivRef = useRef();
  const imgRef = useRef();


  let module_name = sessionStorage.getItem("module_name");
  let testName = state?.testCardData?.name || ""
  let randomexe = JSON.parse(sessionStorage.getItem("randomexe"));  // console.log("randomexe", randomexe)
  let step;
  let isFirstStep;
  let isLastStep;
  step = randomexe[currentStepIndex];
  isFirstStep = currentStepIndex === 0;
  isLastStep = currentStepIndex === randomexe.length - 1;

  let module = randomexe[currentStepIndex]?.module;
  setValueInSession("data", JSON.stringify(randomexe[currentStepIndex]));
  sessionStorage.setItem("index", currentStepIndex);


  const { output, setOutput, resultarr, setResultArr } = useSetResultArrOutputVal(testLoader, setTestLoader, setChange, setIsLimitExceed, randomexe, currentStepIndex, setActiveTab)

  const { answers, numTests, imgDimensions, debugcode, showRunTestCOunt, setCode, imageType, isImageEditor, isShowEditor, isTextEditor } = useQuesAnsAssertTest(randomexe, currentStepIndex, language, username, setIsLoading, setQuesResp, quesresp, testLoader, setTestLoader, setChange, setOutput, setIsLimitExceed, setIsSlide)

  const { toSetEvaluationQuizScore } = useSetEvaluationQuizScore(randomexe, currentStepIndex, resultarr, numTests, debugcode, output, setQuesResp, quesresp, username, setSubmitLoading, setSubmitMsg, scheduleId, showResult, module)

  const { toFinishEvaluate } = useToEvaluateGrandTotal()

  const { compile } = useCompile(testLoader, setTestLoader, setChange, setOutput, setIsLimitExceed, setActiveTab, userLang, setUserLang, debugcode, setResultArr, randomexe, currentStepIndex, setStatusCode)

  const { evaluate } = useEvalFormutils(step.exid)

  function showExerciseID() {
    let show = process.env.REACT_APP_SHOW_EXID;
    return show !== undefined && show === "true";
  }
  let showEx = showExerciseID();
  let Api = new ApiConfig();
useMemo(()=>{

  setResultArr([])
  setOutput('')
  setActiveTab('tab1');

},[currentStepIndex])

  useMemo(() => {
    // let language = sessionStorage.getItem('language');
    setUserLang(language);
    // console.log();
  }, [currentStepIndex]);

  function clearTimer() {
    while (timerid--) {
      clearTimeout(timerid);
    }
  }
  // console.log("execode.data", code);
  // console.log("code", code);
  const navigate = useNavigate();


  function next() {
    console.log('testLoader',testLoader)
    if(testLoader){
      return
    }
    setCurrentStepIndex((index) => {
      if (index >= randomexe.length - 1) {
        return index;
      }
      setIsLoading(true);
      return index + 1;
    });
  }

  function back() {
    if(testLoader){
      return
    }
    setCurrentStepIndex((index) => {
      if (index <= 0) {
        return index;
      }
      setIsLoading(true);
      return index - 1;
    });
    // cb()
  }

  const options = {
    readOnly: true,
    domReadOnly: true,
    minimap: { enabled: false },
    wordWrap: "on",
    fontSize: "14rem",
    mouseWheelZoom: true,
    fontFamily: "open sans",
    contextmenu: false,
  };

  const optionsD = {
    readOnly: false,
    domReadOnly: false,
    minimap: { enabled: false },
    wordWrap: "on",
    fontSize: "14rem",
    mouseWheelZoom: true,
    fontFamily: "open sans",
  };
  let userInput = "";
  let no_of_questions;
  let no_of_correct;
  let totalMarks;
  let score;
  let exid;

  console.log("resultarr, setResultArr", resultarr)
  let timerid = 0;
  let Timeout = useMemo(() => {
    const startTime = new Date(parseInt(sessionStorage.getItem("time")));

    console.log("inside usememo time ",)
    setTimer(
      parseInt(Math.ceil(duration / 5) * 5) * 60 * 1000 -
      (Date.now() - startTime)
    );
    // console.log("time ", timer)
    // track.setTimer(false)
    console.log('timer-(60000*9))>=(60000*9)', (timer - (60000 * 5)) > 0, (timer - (60000 * 5)), (60000 * 5), timer)
    if ((timer - (60000 * 5)) > 0) {

      setTimeout(() => {
        setIsTimer(true);
        setSubmitMsg(true);
        setShoe5Min(true)
        setIsMOdal(true);

      }, timer - (60000 * 5));
      // console.log('timerid', timerid)
    }
    return <Timer date={Date.now() + timer} />;
  }, [username]);

  useEffect(() => {
    timerid = setTimeout(async () => {
      // setResult();
      await toFinishEvaluate(randomexe, setQuesResp, quesresp, username);
      toSetEvaluationQuizScore("fromTimer");
      clearTimer();
      console.log('first', timerid)
      navigate('/greeting');
    }, timer);
    return () => clearTimeout(timerid);
  }, [timer]);

  function clearTimer() {
    while (timerid--) {
      // console.log(timerid)
      clearTimeout(timerid);
    }
  }
  function markComplete() {
    setIsMOdal(true)
  }

  const Tabs1 = () => {

    let tabsprops = {
      currentStepIndex,
      setActiveTab,
      activeTab,
      coins: { selected: userLang },
      exdatarr: randomexe,
      testLoader,
      statusCode,
      output,
      resultarr,
      markComplete,
      isMarkComplete,
      override
    }

    return <TabsComponent {...tabsprops} />

  };

  function getLangTitle(lang) {
    let title = '';
    if (lang === 'C') {
      title = 'C Programming'
    } else if (lang === 'Python') {
      title = 'Python Programming'
    } else if (lang === 'JavaScript') {
      title = 'JavaScript Programming'
    } else {
      title = 'Visual Programming'
    }
    return title;
  }

  function showAttemptedNotAttempted() {
    let attNtAttp = JSON.parse(getValueFromSession('attNtAttp'))
    console.log("showAttemptedNotAttempted", attNtAttp);

    return (
      <section className="count-table-container">
        <h4>Summary</h4>
        <section className="table-container count-table">
          <table>
            <thead>
              <th>Exercise no</th>
              <th>Attempted</th>
              <th>Not attempted</th>
            </thead>
            <tbody>
              {attNtAttp.map((row, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td className="attempted">
                    {row.count.attempted}
                  </td>
                  <td className="notattempted">
                    {row.count.notattempted}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </section>
    )

  };

  const handlesetAttNtAttp = (results) => {
    let attNtAttp = getValueFromSession("attNtAttp") ? JSON.parse(getValueFromSession("attNtAttp")) : []; console.log("called")
    const count = attNtAttp[currentStepIndex]?.count
    let count1 = results[currentStepIndex]
    let temp = []
    console.log("count1", count1)
    console.log("results[currentStepIndex].questions", results[currentStepIndex].questions)
    let attempted = 0
    let notattempted = 0
    let q = results[currentStepIndex].questionjson
    for (let i = 0; i < q.length; i++) {
      // results[currentStepIndex].questionjson.forEach((q, i) => {
      console.log("value q[i]", q[i])
      if ('value' in q[i] && q[i].value !== "") {
        attempted++;
      } else if (module === 'debug' || module === 'solver') {
        if ('resultarr' in q[i]) {
          console.log("'resultarr' in")
          attempted = results[currentStepIndex].questions
        } else {
          notattempted = results[currentStepIndex].questions;
        }
      } else {
        notattempted++;

      }
    }
    console.log("attempted", attempted, "notattempted", notattempted)

    count.attempted = attempted
    count.notattempted = notattempted
    temp.push({ attempted: attempted, notattempted: notattempted })
    count1.attNtAttp = temp
    setValueInSession("attNtAttp", JSON.stringify(attNtAttp));
    setValueInSession("results", JSON.stringify(results));
  }

  function fullSizeImage() {
    return <FullSizeImage zoom={zoom}
      imageType={imageType}
      imageUrl={debugcode} setZoom={setZoom} isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} imgDimensions={imgDimensions} />
  }
  function setEditor(module) {
    const baseProps = {
      showAttemptedNotAttempted,
      debugcode,
      randomexe,
      currentStepIndex,
      setCurrentStepIndex,
      isFirstStep,
      isLastStep,
      setIfAttempted,
      ifAttempted,
      next,
      back,
      npvalue,
      evaluate,
      handlesetAttNtAttp,
      step,
      statenp,
      setValue,
      setKey,
      fromPagi,
      prevIndex,
      setPrevIndex,
      clearTimer,
      DynamicForm, resultarr,
      optionsD,
      RunTestBtnActivateInactivate,
      compile,
      change,
      isLimitExceed, setIsLimitExceed,
      setResult, setOutput, setChange,
      setIsMOdal, testLoader, setTestLoader,
      setSubmitMsg,
      toFinishEvaluate,
      isLoading,
      Tabs1,
      setCode,
      setIsLoading,
      output,
      numTests,
      score,
      totalMarks,
      no_of_correct,
      no_of_questions, language,
      setZoom,
      zoom,
      isSlide,
      setIsSlide,
      isFullScreen,
      setIsFullScreen,
      imgDimensions, fullSizeImage,
      imageDivRef,
      imgRef,
      step,
      imageType, handleEditorDidMount
      , isTextEditor, showResult
    };
    return (
      <CustomizableRenderer
        {...baseProps}
        isEvaluation={true}
        code={debugcode}
        isShowEditor={isShowEditor}
        isImageEditor={isImageEditor}
        module={module}
      />
    );

  }



  const handleSetResults = () => {
    setResult(randomexe, currentStepIndex, resultarr, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output)
  }

  const handlePagination = () => {
    return (
      <>
        <Tooltip
          style={{ zIndex: 15 }}
          anchorSelect="#prev"
          content="Previous"
          place="top"
        />
        <Tooltip
          style={{ zIndex: 15 }}
          anchorSelect="#next"
          content="Next"
          place="top"
        />
        <Tooltip
          style={{ zIndex: 15 }}
          anchorSelect="#first"
          content="First"
          place="top"
        />
        <Tooltip
          style={{ zIndex: 15 }}
          anchorSelect="#last"
          content="Last"
          place="top"
        />
        <PaginationEditor dataPerPage={1} dataArray={randomexe}
          next={next} previous={back} currentIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          prevIndex={prevIndex} setPrevIndex={setPrevIndex}
          setIsLoading={setIsLoading} evaluate={evaluate}
          calcAttpNttp={handlesetAttNtAttp} step={step}
          model={npvalue} statenp={statenp} setFromPagi={setFromPagi}
          module={module} handleSetResults={handleSetResults} ifAttempted={ifAttempted}
          testLoader={testLoader}
          />
      </>
    )
  }

  return (
    <>

      <section className="pyui_main-content-section with-alternate-tab">
        <section className="editor-container">
          <div className="information-menu pyui_editor-infobar">
            {showEx ? (
              <div className="editor-title">
                {step !== undefined && step !== null ? (
                  <h3 className="editor-title">
                    <span className="editor-title-pre">
                      {getLangTitleAndShortName(language, step.module, step.category).title} |{" "}
                      {randomexe[currentStepIndex].exid}
                    </span>
                  </h3>
                ) : (
                  ""
                )}
                {sessionStorage.getItem("module_lable") !== null
                  ? sessionStorage.getItem("module_lable")
                  : testName}{" "}
                | Exercise {currentStepIndex + 1} of {randomexe.length} | {step.exid}
              </div>
            ) : (
              <div className="editor-title">
                {step !== undefined && step !== null ? (
                  <h3 className="editor-title">
                    <span className="editor-title-pre">
                      {getLangTitleAndShortName(language, step.module, step.category).title}{" "}
                    </span>
                  </h3>
                ) : (
                  ""
                )}
                {sessionStorage.getItem("module_lable") !== null
                  ? sessionStorage.getItem("module_lable")
                  : testName}{" "}
                | Exercise {currentStepIndex + 1} of {randomexe.length}{" "}
                {/* <span className="exercise-count">
                  
                </span> */}
              </div>
            )}

            {(getValueFromSession("runCount") !== undefined && showRunTestCOunt) && (
              <h3 className="editor-title">
                <span className="run-test-count">
                  Run test count : {getValueFromSession("runCount")}
                </span>
              </h3>
            )}
            {process.env.REACT_APP_SHOW_PAGINATION === 'true' && (
              <section className="pagination_infobar">
                {handlePagination()}
              </section>
            )}
          </div>
          {isLoading ? (
            <div className="formloader-spinner">
              <p>
                Loading
                <PulseLoader
                  color="black"
                  loading={isLoading}
                  cssOverride={override}
                  size={3}
                  className="pulse-loader"
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </p>
            </div>
          ) : (
            <>
              <div className="pyui_code-viewer eval-questions">
                {setEditor(module)}
              </div>
            </>
          )}
          {/* <div className="editor-questions">
            {setEditor(module)}
          </div> */}
          {isModal && (<ModalComponent isModal={isModal} override={override} PulseLoader={PulseLoader} setQuizScore={toSetEvaluationQuizScore} setIsMOdal={setIsMOdal}
            show5min={show5min} setShoe5Min={setShoe5Min} submit_loading={submit_loading} submit_smg={submit_smg} title={"Assessment"} setSubmitMsg={setSubmitMsg}
            showAttemptedNotAttempted={showAttemptedNotAttempted} />)}


        </section>
      </section>
    </>
  );
};

export default EvalEditor;
