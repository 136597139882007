import { Base64 } from 'js-base64'

/**
 * #############################
 * decrypt function is to decrypt sepecific data from result arr
 * ###########################
 */
function decrypt(data) {

    let data3 = []
    if (data !== 'W10=') {

        data = JSON.parse(data)

        for (let i = 0; i < data.length; i++) {
            const element = data[i];

            let data2 = {}

            data2['correct'] = parseInt(Base64.atob(element.Y29ycmVjdA))
            data2['maxscore'] = parseInt(Base64.atob(element.bWF4c2NvcmU))
            data2['questions'] = parseInt(Base64.atob(element.cXVlc3Rpb25z))
            data2['score'] = parseInt(Base64.atob(element.c2NvcmU))
            data2['exercise'] = Base64.atob(element.ZXhlcmNpc2U)
            data2['questionjson'] = element?.cXVlc3Rpb25qc29u

            data3.push(data2)


        }
    }


    return JSON.stringify(data3)
}
/**
 * #############################
 * encrypt function is to encrypt sepecific data from result arr
 * ###########################
 */
function encrypt(data) {

    // data=data.map(item=>{Base64.btoa(item.correct)})


    let correct = Base64.btoa('correct').replaceAll('=', '')
    let maxscore = Base64.btoa('maxscore').replaceAll('=', '')
    let questions = Base64.btoa('questions').replaceAll('=', '')
    let exercise = Base64.btoa('exercise').replaceAll('=', '')
    let score = Base64.btoa('score').replaceAll('=', '')
    let questionjson = Base64.btoa('questionjson').replaceAll('=', '')
    let data3 = []
    for (let i = 0; i < data.length; i++) {
        const element = data[i];

        let data2 = {}

        data2[correct] = Base64.btoa(element.correct)
        data2[maxscore] = Base64.btoa(element.maxscore)
        data2[questions] = Base64.btoa(element.questions)
        data2[score] = Base64.btoa(element.score)
        data2[exercise] = Base64.btoa(element.exercise)
        data2[questionjson] = element?.questionjson
        data3.push(data2)


    }

    return data3

}
function isValidBase64(str) {
    return /^[A-Za-z0-9+/]+={0,2}$/.test(str) && str.length % 4 === 0;
}
/*############################### 

 function getValueFromSession() is to get value from session storage 

#################################*/
function getValueFromSession(key, keyToDecryptArr) {
    try {

        if (process.env.REACT_APP_IS_ENCRYPT_SESSION_DATA === 'true') {
            if (sessionStorage.getItem(Base64.btoa(key)) === null &&  key !== 'attNtAttp') {
                if (key === 'results' || key === 'attNtAttp') {

                    return null
                }
                return undefined
            }
            /* ######################### 

             this condition removes the end character that we have added and get the only username

            ############################*/

            else if (key === 'username') {
                let username = Base64.atob(sessionStorage.getItem(Base64.btoa(key)))
                // let username = Base64.atob(escape(decodeURIComponent(sessionStorage.getItem(Base64.btoa(key)))))
                return username.slice(0, username.indexOf('\\'))
            }
            /* ##########################

                this condition is to get results arr from session 

            ############################## */
            else if (key === 'results') {
                return decrypt((sessionStorage.getItem(Base64.btoa(key))))

            } else if (key === 'attNtAttp') {
                keyToDecryptArr = keyToDecryptArr || ['exid']
                console.log('key',key)
                let value =decryptSpecificValues(key, keyToDecryptArr)
                console.log('value',value)
                return JSON.stringify(value)
            }
            else if(key === 'data'){
                keyToDecryptArr = keyToDecryptArr || ['exid','qlocation','cat_seq','category','ex_seq','language','league','level','module','rank','subcat_seq','subcategoryid','title']

               return decryptSpecificValues(key, keyToDecryptArr)
            }
            else {
                return Base64.atob(sessionStorage.getItem(Base64.btoa(key)))
                // return Base64.atob(escape(decodeURIComponent(sessionStorage.getItem(Base64.btoa(key)))))
            }
        }
        else {
            return sessionStorage.getItem(key)
        }

    }
    catch (error) {

        console.log(error)
    }
}

function decryptSpecificValues(key, keyToEncryptArr){
    let value = sessionStorage.getItem(Base64.encode(key))
    console.log('value',value, keyToEncryptArr)
    if(typeof value === 'string'){
        value = JSON.parse(value)
    }
    let data=[]
    if(Array.isArray(value))
    {
        console.log('value',value)
        for (let i = 0; i < value.length; i++) {
            const element = value[i];
            {for (let j = 0; j < keyToEncryptArr.length; j++) {
                const key = Base64.encode(keyToEncryptArr[j]).replaceAll('=','');
                console.log(key)
                // if(typeof element[key] ==='object'){
                //     element[keyToEncryptArr[j]]= JSON.stringify(element[key])
                // }
                console.log(element[key],element)
                element[keyToEncryptArr[j]]= Base64.decode(element[key])
            }}
            
        }
    }else{
        for (let j = 0; j < keyToEncryptArr.length; j++) {
            // const key = keyToEncryptArr[j];
            const key = Base64.encode(keyToEncryptArr[j]).replaceAll('=','');
            console.log(value)
            value[keyToEncryptArr[j]]= Base64.decode(value[key])
            delete value[key]
        }
    }
    console.log('value',value)
    return value
}

function encryptSpecificValues(value, keyToEncryptArr){
console.log('value',value, keyToEncryptArr)
if(typeof value === 'string'){
    value = JSON.parse(value)
}
let data =[]
    if(Array.isArray(value))
    {
        console.log('value key',value)
        for (let i = 0; i < value.length; i++) {
            const element = value[i];
            for (let j = 0; j < keyToEncryptArr.length; j++) {
                const key = Base64.encode(keyToEncryptArr[j]).replaceAll('=','');
                console.log(key,Base64.encode(element[keyToEncryptArr[j]]),element[keyToEncryptArr[j]])
                // data.push({key:Base64.encode(element[key])})
                element[key] =Base64.encode(element[keyToEncryptArr[j]])
                delete element[keyToEncryptArr[j]]

            }
            
        }
    }else{
        data ={}
        for (let j = 0; j < keyToEncryptArr.length; j++) {
            // const key = keyToEncryptArr[j];
            const key = Base64.encode(keyToEncryptArr[j]).replaceAll('=','');
            console.log('value',value[keyToEncryptArr[j]])
            value[key]= Base64.encode(value[keyToEncryptArr[j]])
            delete value[keyToEncryptArr[j]]
        }
    }
    console.log('data',data)
    return JSON.stringify(value)
}
/*###################################################

    below function is to encrypted value in session storage it takes key and value related to the key and encrypt both, save in session storage

#####################################################*/
function setValueInSession(key, value, keyToEncryptArr) {
    try {
        if (process.env.REACT_APP_IS_ENCRYPT_SESSION_DATA === 'true') {

            if (key === 'username') {
                value += '\\0'
                sessionStorage.setItem(Base64.btoa(key), Base64.btoa((value)))
            }
            else if (key === 'results') {

                if (value !== '[]') {
                    value = encrypt(JSON.parse(value))

                    sessionStorage.setItem(Base64.btoa(key), JSON.stringify(value))
                }
                else {
                    sessionStorage.setItem(Base64.btoa(key), Base64.btoa(value))
                }
            } else if (key === 'attNtAttp') {
                // sessionStorage.setItem(key, value)
                keyToEncryptArr = keyToEncryptArr || ['exid']

                value=encryptSpecificValues(value, keyToEncryptArr)
                console.log(value)
                key = Base64.encode(key)
                sessionStorage.setItem(key, value)
            }
            else if(key === 'data'){
                keyToEncryptArr = keyToEncryptArr || ['exid','qlocation','cat_seq','category','ex_seq','language','league','level','module','rank','subcat_seq','subcategoryid','title']
                value=encryptSpecificValues(value, keyToEncryptArr)
                console.log(value)
                key = Base64.encode(key)
                sessionStorage.setItem(key, value)
            }
           else if(!isValidBase64(Base64.btoa((value)))){
                sessionStorage.setItem(key, value)
                return 
            }
            else {
                // console.log("value",value.length,Base64.btoa(value).length)
                sessionStorage.setItem(Base64.btoa(key), Base64.btoa(value))
            }


        }
        else {
            sessionStorage.setItem(key, value)
        }
    }
    catch (error) {
        console.log(error)
    }
}

/* #####################################################

below function is to validate user accorading to the string added at the end of the user name


########################################################*/
function validateUser(username) {

    try {


        /* ####################################### 

        process.env.REACT_APP_IS_ENCRYPT_SESSION_DATA  this env is used to on, off the session data encryption
        
        #########################################*/
        if (process.env.REACT_APP_IS_ENCRYPT_SESSION_DATA === 'true') {
            let user = Base64.atob(sessionStorage.getItem(Base64.btoa('username')))
            if (user !== undefined) {

                /*#####################################

                    (parseInt(user[user.indexOf('\\') + 1]) === 0) this condition is checks the end string is valid or not 

                ######################################*/
                if (parseInt(user[user.indexOf('\\') + 1]) === 0) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false
            }
        }
        else {
            return true
        }
    } catch (error) {
        console.log(error)
    }
}

export { getValueFromSession, setValueInSession, validateUser }