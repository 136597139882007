import { useState } from "react";
import { fetchData } from "../ApiUtils/fetchData";
import { postData } from "../ApiUtils/postData";
import {
  getValueFromSession,
  setValueInSession,
} from "../UtilityFunctions/getSetValueSession";

const useAccountSettings = (flag = true) => {
  const [showPercentile, setShowPercentile] = useState(true);
  const [notificationSettings, setNotificationSettings] = useState({
    start: true,
    schedule: true,
    update: true,
  });
  const [useRandom, setUseRandom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});
  let group_name = getValueFromSession("group_name");
  // console.log('group name', group_name);

  // useEffect(() => {
  //   if (flag)
  //     getAccountSettings()
  // }, [])

  async function getAccountSettings(group_name) {
    if (group_name === undefined) {
      group_name = getValueFromSession("group_name");
    }
    // console.log('response.data.group_name %%%',group_name)

    setLoading(true);
    if (group_name === undefined || group_name === null)
      group_name = getValueFromSession("group_name");
    let uri = `getsettings?group_name=${group_name}`;
    const resp = await fetchData(uri);
    console.log("res", resp);
    console.log("parse", JSON.parse(resp));
    setValueInSession("settings", resp);
    setSettings(resp);
    const data = JSON.parse(resp);
    console.log("data", data);
    setShowPercentile(data.percentile);
    setNotificationSettings(data.notifications);
    setUseRandom(data.randomExercies);
    setLoading(false);
    return data;
  }

  async function saveSettings(reset) {
    let group_name = getValueFromSession("group_name");
    setLoading(true);
    let uri = "settings";
    let settings = {
      percentile: showPercentile,
      notifications: {
        start: notificationSettings.start,
        schedule: notificationSettings.schedule,
        update: notificationSettings.update,
      },
      randomExercies: useRandom,
    };
    let data = {};
    if (reset) {
      data = {
        group_name: group_name,
        settings: settings,
        setdefaultsettings: reset,
      };
    } else {
      data = { group_name: group_name, settings: settings };
    }

    const response = await postData(uri, data);
    console.log("reponse save", response);
    setLoading(false);
  }
  return {
    saveSettings,
    setShowPercentile,
    setNotificationSettings,
    showPercentile,
    notificationSettings,
    getAccountSettings,
    loading,
    settings,
    useRandom,
    setUseRandom,
  };
};

export default useAccountSettings;
